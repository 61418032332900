import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from 'firebase'
import axios from 'axios'
import VueApexCharts from 'vue-apexcharts'
import vuetify from '@/plugins/vuetify'
import GAuth from 'vue-google-oauth2'
import VueCookies from 'vue-cookies'


Vue.use(require('vue-cookies'))


const gauthOption = {
  clientId: '604074735647-tjk7oipvdlhs8db18711ng5elb66ius0.apps.googleusercontent.com',
  scope: 'profile email https://www.googleapis.com/auth/admin.directory.user.readonly https://www.googleapis.com/auth/calendar',
  prompt: 'consent'
}
Vue.use(GAuth, gauthOption, VueCookies)

Vue.prototype.$http = axios

Vue.component('apexchart', VueApexCharts)

Vue.config.productionTip = false


new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
