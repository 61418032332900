<template>
  <v-container>
    <v-data-table
        v-model="selected"
        :headers="headers"
        :items="objects"
        :pagination.sync="pagination"
        select-all
        item-key="name"
        class="elevation-1"
      >
        <template v-slot:headers="props">
          <tr>
            <th>
              <v-checkbox
                :input-value="props.all"
                :indeterminate="props.indeterminate"
                primary
                hide-details
                @click.stop="toggleAll"
              ></v-checkbox>
            </th>
            <th
              v-for="header in props.headers"
              :key="header.text"
              :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
              @click="changeSort(header.value)"
            >
              <v-icon small>arrow_upward</v-icon>
              {{ header.text }}
            </th>
          </tr>
        </template>
        <template v-slot:items="props">
          <tr :active="props.selected" @click="props.selected = !props.selected">
            <td>
              <v-checkbox
                :input-value="props.selected"
                primary
                hide-details
              ></v-checkbox>
            </td>
            <td class="text-xs-right">{{ props.item.name }}</td>
            <td class="text-xs-right">{{ props.item.type }}</td>
            <td class="text-xs-right">{{ props.item.size }} mb</td>
            <td class="text-xs-right"><v-btn @click="nav(props.item.path)">Go</v-btn></td>
          </tr>
        </template>
      </v-data-table>
  </v-container>
</template>

<script>
  import firebase from 'firebase'
    // Get a reference to the storage service, which is used to create references in your storage bucket
  var storage = firebase.storage();
  // Create a storage reference from our storage service
  var storageRef = storage.ref();

  export default {
    data () {
      return {
        loading: true,
        currentPath: '/',
        pagination: {
          sortBy: 'name'
        },
        selected: [],
        headers: [
          {
            text: 'Name',
            align: 'left',
            value: 'name'
          },
          { text: 'Type', value: 'type' },
          { text: 'Size', value: 'size' },
          { text: 'Action', value: 'action' }
        ],
        folders: [],
        items: [],
        objects:[],
        clean: [],
      }
    },
    created () {
      // Find all the prefixes and items.
      var listRef = storageRef.child('/');

      listRef.listAll().then(res => {
        res.items.forEach(items => {
          storageRef.child(items.fullPath).getMetadata().then(Metadata => {
            var sizeS = Metadata.size / 1000000
            var size = sizeS.toFixed(1)
              var data = {
                'name': items.name,
                'path': items.fullPath,
                'size': size,
                'type': Metadata.type,
                'metadata': Metadata,
              }
              this.objects.push(data)
            });
        });
        res.prefixes.forEach(prefixes => {
              var data = {
                'name': prefixes.name,
                'path': prefixes.fullPath,
                'type': 'Folder',
              }
              this.objects.push(data)
              console.log(data)
        });
        //  this.folders = res.prefixes
        //  this.items = res.items
      }).catch(function(error) {
        // Uh-oh, an error occurred!
        console.log(error)
      });
    },
    methods: {

      toggleAll () {
        if (this.selected.length) this.selected = []
        else this.selected = this.desserts.slice()
      },
      changeSort (column) {
        if (this.pagination.sortBy === column) {
          this.pagination.descending = !this.pagination.descending
        } else {
          this.pagination.sortBy = column
          this.pagination.descending = false
        }
      },
      nav: function(path) {
          var stringpath = path.toString
          storageRef.child('/' + this.path).listAll().then(res => {
          console.log(res)

          res.prefixes.forEach(prefixes => {
                var path = prefixes.fullPath.toString
                var data = {
                  'name': prefixes.name,
                  'path': path,
                  'type': 'Folder',
                }
                this.objects.push(data)
                console.log(data)
          });
            res.items.forEach(items => {
              storageRef.child(items.path).getDownloadURL().then(url => {
                  var data = {
                    'name': items.name,
                    'path': items.fullPath,
                    'url': url
                  }
                  this.objects.push(data)
                  console.log(data)
                });
            });
        });
      },
      downL: function(url) {
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function(event) {
          var blob = xhr.response;
        };
        xhr.open('GET', url);
        xhr.send();
      },
    }
  }
</script>
