<template>
  <v-container grid-list-md text-center>
    <v-layout wrap>
      <v-flex xs12>
        <v-breadcrumbs  divider="/">
          <template>
            <v-breadcrumbs-item
            href="/#/Colleague"
            >
              Colleagues
            </v-breadcrumbs-item>
            <v-breadcrumbs-item
              :href="'/#/Colleague?dp=' + returnId "
            >
              {{ user.organizations[0].department }}
            </v-breadcrumbs-item>
            <v-breadcrumbs-item>
              {{ user.name.fullName }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </v-flex>

      <v-flex xs3>
        <v-img
          v-for="organization in user.organizations"  :key="3"
          lazy-src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png"
         :src="organization.costCenter"></v-img>
      </v-flex>
      <v-flex offset-xs2 xs4 pl-5>
        <p class="display-3 font-weight-thin">{{ user.name.fullName }}</p> </br>
        <div v-for="organization in user.organizations"  :key="0">
        <p class="headline font-weight-thin">{{ organization.department }}</p><p class="headline font-weight-medium">{{ organization.title }}</p>
       </div>
       <div>
         <p>{{ user.primaryEmail }}</p>
         <p v-if="user.phones[0].value">{{ user.phones[0].value }}</p>
       </div>
       <div v-for="relation in user.relations"  :key="1">
         <p>{{ user.name.givenName }}'s manager: <a :href=" 'mailto:' + relation.value ">{{ relation.value }}</a> </p>
       </div>
       <div v-if="user.customSchemas">
        <p>Work description</p>
        <p>{{ user.customSchemas.Work.Responsability }}</p>
       </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from 'axios'
import firebase from 'firebase'
import KJUR from 'jsrsasign'
  export default {
    name: 'employee',
    data () {
      return {
        user: [],
        returnId: null
      }
    },
    methods: {
      weGottaGoBackMorty: function () {

      }
    },
    mounted () {
      if (this.$route.params.userKey < 10000) {
        axios({
          url: 'https://europe-west2-idos-4bb5f.cloudfunctions.net/intranet_internsimport',
          method: 'get',
          headers: {
               'Accept': 'application/json',
               'Authorization': 'Basic YjA0ZDg4YTQ1Zjc2MmJmNGY5NTZlOGRjOGZhYWRmM2Y6MTdiM2E1MDNjMjE3ZWNkYjAxMDcyZTM0MThjMjhkMTI=',
          }
            }).then(response => {
              var list = response.data;
              var now = Date.now();
              var result = list.filter(emp => emp.AA_Employee_number == this.$route.params.userKey);
              console.log(result)
              var tempArr = [];
              for (var i = 0; i < result.length; i++) {
                let dat = {
                  "gotResp": false,
                  "id": result[i].AA_Employee_number,
                  "emails": [{
                    "address": result[i].DJ_Private_mail,
                    "primary": true
                  }],
                  "locations": [{
                    "area": "desk",
                    "buildingId": result[i].DD_Location,
                    "floorName": "",
                    "type": "desk"
                  }],
                  "name": {
                    "familyName": result[i].AB_Surname,
                    "fullName": result[i].AC_First_names + " " + result[i].AB_Surname,
                    "givenName": result[i].AC_First_names
                  },
                  "organizations": [{
                    "costCenter": "",
                    "customType": "",
                    "description": "Intern",
                    "department": "Influencer Marketing",
                    "primary": true,
                    "title": result[i].Employment_journey[result[i].Employment_journey.length - 1].Role
                  }],
                  "phones": [{
                    "type": "work",
                    "value": result[i].DF_Phone_number
                  }],
                  "primaryEmail": result[i].DJ_Private_mail,
                  "relations": [{
                    "type": "manager",
                    "value": result[i].AF_Manager_Email
                  }]
                }
                if (typeof result[i].Profile_picture[0] != "undefined") {
                dat.organizations[0].costCenter = result[i].Profile_picture[result[i].Profile_picture.length - 1].Profile_picture_column
                }
                this.user = dat;
                this.returnId = 0
              }
              })
      } else {
        var pHeader = {"alg":"RS256","typ":"JWT"}
        var sHeader = JSON.stringify(pHeader);
        var key = "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDLCCJQk/oBYTn5\nQ1J08vP1vLRWAKBB20hhr+8yhC3/u9VQXG1vc+0PyIr5dIcmLOXJfe537Ou0Dpfy\n+r931HDBVwJaJoYNEWhY9Wv5lrw1RJs8kK8enH1iX31Qkc5+VbpArD1QF8Nn4bsE\n0A2HVFzIOaWjsIvK3Htx8VXW0eTNpXpSS9H3/rhJPg1Y48y3LVNf4ibyrdgmH/qF\nULRJmHtBwmnwoWFNyCkRB7ZXAX6hHPGQ6ItH3KWJQ8bFtQ4wibkrzuQMv+xysXG3\nvQA1ybndxq3RPeO4gNgzvHIZTjYJBYuNC5rkGzfGEiVuNzpcsrYVLMg+ZwCCLEPD\n+hqY9X1hAgMBAAECggEABTRgLNCeSArGAa3IH1dbCaGcvdemRAnrARYRe6oHw9Z8\n0WKNK549JXpulZZ3IHNmMJQZ2Nkp8aOuIXxsDBZWDZ69YLI8HXmkwyMHcY57pKHt\nmM/crVw1mp/4Bmi2cmagL6g6qiLxz2fp82Wh8yYmert/k4KjEsA0KeeV27vlsZ/Q\nkrFZPrC5oSTP5OpguJwOZ/A661h503Z/zgnpqOjhm0sLHPcEkt5revBKNlvBIARM\nejf7e2HRjrOcJV5uC/s2Muop7pmorqWWrVFTmFgLfgX4dJoEvP0s7yr4AjqFnTop\nv99g8B8Ysbd1bptMMPjFKx0bX+nV18LdXkxnr2dMAQKBgQD8xY6ExyRgbiFADiki\nc71MirrFLnoUITGjthldykHnALl0te5JwcWRi2TTJHI8rZh6o3j1vg/ciaiwe3kL\n7n8/GEmPGwmlsSPh2wPU9kq5qiUbsHupwnrwVleOYfqMRCas1jEJVMw1+KpvqyZX\nWkG0AT8j0Nr6nDbNd4STrqvKOQKBgQDNn/OPA3Ks54pbqD/mwrWpk4h4Yik5qqwD\nOQL2+0s5aI4TtEeU0kI74u2u/AMlVqch0e/vPDE2oiu4Ri9MQKFOed2Vb/IeexyZ\nK/X7c1xIa3wDG4Fpp7Zf44ENlz72m3T4AQs0/QSFVepHH5o2eSbt+zNbvTXQBFPE\nI/XVkqvsaQKBgQD0AUEyq4pSC/3M9959g3yZBtKExfjStbhVcNPL1hMIC4r7PyRn\nzsYJWaZarDQanvkyadu6XV6yZbCRrJ+NBQ2QusFHg7IsYHNi8ykfyyEXWUe5EwRN\nO4+xsaeTqsFR5ffvoFR1zYXUE9dzNKQubtI+EXYB/PtmMKY2cclpRpRPAQKBgQCC\nRUPY5yZPxKEzUHSrjc/pjz52hjn3/tjbcdMfBjWyOTtJ4H05G7w6lJpgseiMT0mz\nc32+4zOKmVN5e32boZaQUUTA2BtclEKaB+/fTka42t/GWDa06TvxpACuy5NoSTTh\nqkefWmzyzGuQg8T/neG8A9mV1VmyYAtxyBaHKFUjgQKBgHsMG7xOZGTFR6ob68JS\nPmjeOR3kKC8vIRd7usWi1w9ZvP3cY1s5hgyLyUjc+KN+eeFLP20nugHzPklDF2/v\nlwrYh8BdJzEAIOXBNI5PTZ/fLey6eM1Ep5cd7oflOMLNHSyo5tawr0c14MLYvsw/\n3g4txWFg8v4ibK/NafsFrdSf\n-----END PRIVATE KEY-----\n";`4616`
        var pClaim = {
          "aud": "https://oauth2.googleapis.com/token",
          "scope": "https://www.googleapis.com/auth/admin.directory.user",
          "iss": "idos-425@intranet-261309.iam.gserviceaccount.com",
          "exp": Math.floor(Date.now()/1000)+3600,
          "iat": Math.floor(Date.now()/1000),
          "sub": "daniel.eriksson@idealofsweden.com"
          };
        var sClaim = JSON.stringify(pClaim);

        var sJWS = KJUR.jws.JWS.sign(null, sHeader, sClaim, key);
        axios({
          url: 'https://oauth2.googleapis.com/token',
          method: 'post',
          data: {
                   'grant_type': 'urn:ietf:params:oauth:grant-type:jwt-bearer',
                   'assertion': sJWS
                }
            }).then(response => {
              //this.tokki.push(response.data)
                var token = response.data.access_token //$cookies.get("aW50cmFuZXRjcmVkZW50aWFscw==").token
        axios({
          url: 'https://www.googleapis.com/admin/directory/v1/users',
          method: 'get',
          headers: {
               'Accept': 'application/json',
               'Authorization': 'Bearer ' + token,
          },
          params: {
                   'viewType': 'domain_public',
                   'domain': 'idealofsweden.com',
                   'projection': 'full',
                   'maxResults': 500,
                   'key': '60a0a2c29f86e61a0f9b3d781ddf34c4be166d2d'
                  }
            }).then(response => {
              var res = response.data.users.find(emp => emp.id == this.$route.params.userKey);
              console.log(res)
              this.user = res
              var dep = res.organizations[0].department
              if (dep === 'Influencer Marketing') {
                this.returnId = 0
              }
              if (dep === 'Marketing' || dep === 'Marketing Campaign' || dep === 'Event' || dep === 'Content') {
                this.returnId = 1
              }
              if (dep === 'Design') {
                this.returnId = 2
              }
              if (dep === 'E-Commerce' || dep === 'IT' || dep === 'Online Marketplaces' || dep === 'Performance Marketing') {
                this.returnId = 3
              }
              if (dep === 'Customer Service') {
                this.returnId = 4
              }
              if (dep === 'Supply Chain') {
                this.returnId = 5
              }
              if (dep === 'Sales') {
                this.returnId = 6
              }
              if (dep === 'HR') {
                this.returnId = 7
              }
              if (dep === 'Finance') {
                this.returnId = 8
              }
              if (dep === 'Product Development') {
                this.returnId = 9
              }
              if (dep === 'Office') {
                this.returnId = 11
              }
              console.log(response)
            })
          })



        .catch(function (error) {
          console.log(error);
        });
      }

    }
  }
</script>
