<template>
  <v-container>
       <v-layout row wrap>
      <v-flex xs12>
        <iframe width="100%" height="460px" scrolling="no" src="https://app.klipfolio.com/published/0361183eafa079d3159f39834009a4c3/intraweb"></iframe>
        <!--
        <v-card
          class="mt-4 mb-5 mx-auto"

        >
          <v-sheet
            class="v-sheet--offset mx-auto"
            color="#041A36"
            elevation="20"
            max-width="calc(100% - 32px)"
          >
            <apexchart height="350" type="area" :options="chartOptions" :series="series"></apexchart>
          </v-sheet>

          <v-card-text class="pt-0">
            <div class="title font-weight-light mb-2">Revenue last 7 days</div>
          </v-card-text>
        </v-card>
      -->
      </v-flex>
    </v-layout>
    <v-layout row wrap mt-2>
      <v-flex xs12 md3>
        <div class="display-1 text-center mb-5 font-weight-light">Appreciation Wall</div>
        <div v-for="appIndex in appShow" v-bind:key="appIndex">
        <v-card class="mx-auto mb-4" v-if="typeof appreciations[appIndex] != 'undefined'">
        <v-card-text>
            <p class="body-1 text--black font-weight-light" style="color:black !important"><b>{{ appreciations[appIndex].category }}</b> - {{ appreciations[appIndex].text }}</p>
          </v-card-text>
          <v-card-actions v-if="email == appreciations[appIndex].mention">
            <v-list-item class="grow">
              <v-list-item-content>
              </v-list-item-content>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on">
                      insert_emoticon
                    </v-icon>
                  </v-btn>
                </template>
                  <span>This post mentions you!</span>
              </v-tooltip>
            </v-list-item>
          </v-card-actions>
      </v-card>
    </div>
      <v-btn style="margin: 0 auto 0" @click="appShow += 5">Load more</v-btn>
      <!--  <v-card
          class=" mb-4"
          max-width="500"
          v-for="job in jobs"  :key="job.id"
        >
          <v-img
            class="white--text"
            height="200px"
            :src="job.image"
            gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
          >
            <v-card-title class="align-end fill-height">{{job.title}}</v-card-title>
          </v-img>

          <v-card-text>
            <span class="font-weight-black">{{job.location}} | {{job.deadline}}</span><br>
            <span class="text--primary">
              <span>{{job.desc}}</span><br>
            </span>
          </v-card-text>

          <v-card-actions>
            <v-btn
              class="white--text"
              color="#041A36"
              :href="job.apply_url"
              target="blank"
            >
              Apply
            </v-btn>
            <v-spacer></v-spacer>
            <v-speed-dial
              :v-model="job.open"
              class="floatBR"
              direction="top"
              open-on-hover
              transition="slide-y-reverse-transition"
            >
              <template v-slot:activator>
                <v-btn
                  :v-model="job.open"
                  color="#041A36"
                  small
                  dark
                  fab
                >
                  <v-icon v-if="job.open">mdi-close</v-icon>
                  <v-icon v-else>share</v-icon>
                </v-btn>
              </template>
              <v-btn
                :href="'http://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(job.apply_url)"
                target="blank"
                fab
                dark
                small
                color="#041A36"
              >
                <v-icon>fab fa-facebook-f</v-icon>
              </v-btn>
              <v-btn
                :href="'https://twitter.com/intent/tweet?text=' + job.apply_url"
                target="blank"
                fab
                dark
                small
                color="#041A36"
              >
                <v-icon>fab fa-twitter</v-icon>
              </v-btn>
              <v-btn
                :href="' https://www.linkedin.com/sharing/share-offsite/?url=' + encodeURIComponent(job.apply_url)"
                target="blank"
                fab
                dark
                small
                color="#041A36"
              >
                <v-icon>fab fa-linkedin-in</v-icon>
              </v-btn>
            </v-speed-dial>
          </v-card-actions>
        </v-card> -->
    </v-flex>
    <v-flex xs12 md6>
      <div class="display-1 text-center mb-5 font-weight-light">News</div>
  <v-card
  v-for="(post, index) in posts"
    :key="index"
  class="mx-4 mb-4"
>
  <v-card-title>
    <span class="title font-weight-bold">{{post.title}}</span>
    <v-spacer></v-spacer>
    <span v-if="new Date().toISOString().slice(0,10) === post.date" class="title font-weight-light"> {{post.time}} </span>
    <span v-else class="title font-weight-light">{{post.date}}</span>
  </v-card-title>
  <v-card-text class="body-1 font-weight-light black--text" v-html="post.broadtext"></v-card-text>
  <div v-if="typeof post.photo == 'string' && typeof post.photo != 'undefined'">
  <v-img
    v-bind:src="post.photo"
    max-height="500"
    contain
  ></v-img>
</div>
<div v-else-if="Array.isArray(post.photo) && post.photo.length <= 1">
<v-img
  v-bind:src="post.photo[0]"
  max-height="500"
  contain
></v-img>
</div>
<div v-else-if="Array.isArray(post.photo) && post.photo.length > 1">
    <v-item-group>
      <v-container class="pa-0">
        <v-row>
          <v-col
            v-for="(img, index) in post.photo"
            :key="index"
            cols="12"
            md="6"
            v-on="on"
          >
            <v-item >
                <v-img
                  v-if="img.includes('png') || img.includes('jpg') || img.includes('jpeg') || img.includes('gif')"
                  :src="img"
                  height="300"
                  contain
                  class="text-right pa-2"
                >
                </v-img>
                <video v-else width="100%" height="300" controls>
                  <source :src="img" type="video/mp4">
                </video>
            </v-item>
          </v-col>
        </v-row>
      </v-container>
    </v-item-group>
</div>
  <v-card-actions>
    <v-list-item class="grow">
      <v-list-item-avatar color="grey darken-3">
        <v-img
          class="elevation-6"
          v-bind:src="post.authorpic"
        ></v-img>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>{{post.author}}</v-list-item-title>
      </v-list-item-content>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" v-on:click="like(post.id, post.likes, post.likess)">
            <v-icon v-if="post.likes.includes(name)">
              favorite
            </v-icon>
            <v-icon v-else>
              favorite_border
            </v-icon>
          </v-btn>
        </template>
        <div v-for="name in post.likes"  key="i" class="text-center align-center">
          <span>{{ name }}</span><br>
        </div>
      </v-tooltip>

          <div v-if="post.likess != 0">{{post.likess}}</div>
        <v-btn icon v-on:click="post.showComments = !post.showComments">
            <v-icon>comment</v-icon>
        </v-btn>
        <div v-if="post.commentss != 0">{{post.commentss}}</div>

    </v-list-item>
  </v-card-actions>
<div v-show="!post.showComments">
<v-list>
  <div v-for="(comment, index) in comments" :key="index">
    <v-list-item
            v-if="post.id === comment.postId"
            color
            avatar
          >
            <v-list-item-avatar>
              <v-avatar size="30" >
                <img :src="comment.authorpic" alt="avatar">
            </v-avatar>
          </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ comment.author }}</v-list-item-title>
              <v-list-item-subtitle>{{ comment.text }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
        </v-list>

  <v-form ref="form">
    <v-list two-line style="background-color: white; color:black">
    <v-list-item>
   <v-list-item-avatar>
     <v-avatar size="36">
       <img :src="photo" alt="avatar">
     </v-avatar>
   </v-list-item-avatar>

   <v-list-item-content>
     <v-list-item-title>
        <v-textarea
        class="mt-1"
         v-model="Com"
         name="Comment"
         value="Comment..."
         clearable
         outlined
       ></v-textarea>
      </v-list-item-title>
   </v-list-item-content>

   <v-list-item-action>
     <v-btn color="success" v-on:click="comment(post.id, post.comments, post.commentss)">
       Send
   </v-btn>
 </v-list-item-action>
</v-list-item>
</v-list>
  </v-form>
</div>
</v-card>
</v-flex>
<v-flex xs12 md3>
  <div v-if="bDays.length > 0">
  <div class="display-1 text-center mb-5 font-weight-light">Birthdays this week</div>
  <v-card
  class="mx-auto mb-3"
>
  <v-card-text>
    <div v-for="(user, index) in bDays"  :key="index">
      <p class="headline text--black">
        <a style="text-decoration:none; color:black !important" :href="'/#/Colleague/' + user.id">{{ user.name }}</a>
      </p>

      <p>{{ user.day }} - {{ user.date }}</p>
    </div>
  </v-card-text>
</v-card>
</div>
<div class="display-1 text-center mb-5 font-weight-light">Open positions</div>
<v-card
    class=" mb-4"
    max-width="500"
    v-for="job in jobs"  :key="job.id"
  >
    <v-img
      class="white--text"
      height="200px"
      :src="job.image"
      gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
    >
      <v-card-title class="align-end fill-height">{{job.title}}</v-card-title>
    </v-img>

    <v-card-text>
      <span class="font-weight-black">{{job.location}} | {{job.deadline}}</span><br>
      <span class="text--primary">
        <span>{{job.desc}}</span><br>
      </span>
    </v-card-text>

    <v-card-actions>
      <v-btn
        class="white--text"
        color="#041A36"
        :href="job.apply_url"
        target="blank"
      >
        Apply
      </v-btn>
      <v-spacer></v-spacer>
      <v-speed-dial
        :v-model="job.open"
        class="floatBR"
        direction="top"
        open-on-hover
        transition="slide-y-reverse-transition"
      >
        <template v-slot:activator>
          <v-btn
            :v-model="job.open"
            color="#041A36"
            small
            dark
            fab
          >
            <v-icon v-if="job.open">mdi-close</v-icon>
            <v-icon v-else>share</v-icon>
          </v-btn>
        </template>
        <v-btn
          :href="'http://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(job.apply_url)"
          target="blank"
          fab
          dark
          small
          color="#041A36"
        >
          <v-icon>fab fa-facebook-f</v-icon>
        </v-btn>
        <v-btn
          :href="'https://twitter.com/intent/tweet?text=' + job.apply_url"
          target="blank"
          fab
          dark
          small
          color="#041A36"
        >
          <v-icon>fab fa-twitter</v-icon>
        </v-btn>
        <v-btn
          :href="' https://www.linkedin.com/sharing/share-offsite/?url=' + encodeURIComponent(job.apply_url)"
          target="blank"
          fab
          dark
          small
          color="#041A36"
        >
          <v-icon>fab fa-linkedin-in</v-icon>
        </v-btn>
      </v-speed-dial>
    </v-card-actions>
  </v-card>
<!--
<div v-if="newJoin.length > 0">
  <div class="display-1 text-center mb-5 font-weight-light">New Joiners Last Week</div>
  <v-card
  class="mx-auto mb-3"
>
  <v-card-text>
    <div v-for="(usr, index) in newJoin"  :key="index">
      <p class="headline text--black">
        {{ usr.name }}
      </p>
      <p>{{ usr.title }} - {{ usr.dep }}</br>{{ usr.location }}</p>
    </div>
  </v-card-text>
</v-card>
</div>
-->
</v-flex>
</v-layout>
</v-container>
</template>

<script type="text/javascript" src="https://embed.klipfolio.com/a/js/embed.api"></script>
<script type="text/javascript">
KF.embed.embedKlip({
   profile : "9789b4c1a4dffc9691d239414657dfd0",
   settings : {"width":1832,"theme":"light","borderStyle":"round","borderColor":"#cccccc"},
   title : "Order Budget vs Forecast per hour  - Today"
});
</script>
<script>
  import firebase from 'firebase'
  import axios from 'axios'
  import klip from '../components/klip'
  import VuePureLightbox from 'vue-pure-lightbox'
  import styles from 'vue-pure-lightbox/dist/VuePureLightbox.css'
  import KJUR from 'jsrsasign'


  var db = firebase.firestore();

  export default {
    components: {
      klip,
      VuePureLightbox
  },
    data () {
      return {
        posts: [],
        appreciations: [],
        Com: '',
        instaposts: [],
        comments: [],
        photo: $cookies.get("aW50cmFuZXRjcmVkZW50aWFscw==").picture,
        userId: $cookies.get("aW50cmFuZXRjcmVkZW50aWFscw==").id,
        name: $cookies.get("aW50cmFuZXRjcmVkZW50aWFscw==").fullname,
        email: $cookies.get("aW50cmFuZXRjcmVkZW50aWFscw==").email,
        user: {},
        jobs: [],
        bDays: [],
        newJoin: [],
        dialog: false,
        instaShow: 3,
        appShow: 7
      }
    },
    created () {


      var pHeader = {"alg":"RS256","typ":"JWT"}
      var sHeader = JSON.stringify(pHeader);
      var key = "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDLCCJQk/oBYTn5\nQ1J08vP1vLRWAKBB20hhr+8yhC3/u9VQXG1vc+0PyIr5dIcmLOXJfe537Ou0Dpfy\n+r931HDBVwJaJoYNEWhY9Wv5lrw1RJs8kK8enH1iX31Qkc5+VbpArD1QF8Nn4bsE\n0A2HVFzIOaWjsIvK3Htx8VXW0eTNpXpSS9H3/rhJPg1Y48y3LVNf4ibyrdgmH/qF\nULRJmHtBwmnwoWFNyCkRB7ZXAX6hHPGQ6ItH3KWJQ8bFtQ4wibkrzuQMv+xysXG3\nvQA1ybndxq3RPeO4gNgzvHIZTjYJBYuNC5rkGzfGEiVuNzpcsrYVLMg+ZwCCLEPD\n+hqY9X1hAgMBAAECggEABTRgLNCeSArGAa3IH1dbCaGcvdemRAnrARYRe6oHw9Z8\n0WKNK549JXpulZZ3IHNmMJQZ2Nkp8aOuIXxsDBZWDZ69YLI8HXmkwyMHcY57pKHt\nmM/crVw1mp/4Bmi2cmagL6g6qiLxz2fp82Wh8yYmert/k4KjEsA0KeeV27vlsZ/Q\nkrFZPrC5oSTP5OpguJwOZ/A661h503Z/zgnpqOjhm0sLHPcEkt5revBKNlvBIARM\nejf7e2HRjrOcJV5uC/s2Muop7pmorqWWrVFTmFgLfgX4dJoEvP0s7yr4AjqFnTop\nv99g8B8Ysbd1bptMMPjFKx0bX+nV18LdXkxnr2dMAQKBgQD8xY6ExyRgbiFADiki\nc71MirrFLnoUITGjthldykHnALl0te5JwcWRi2TTJHI8rZh6o3j1vg/ciaiwe3kL\n7n8/GEmPGwmlsSPh2wPU9kq5qiUbsHupwnrwVleOYfqMRCas1jEJVMw1+KpvqyZX\nWkG0AT8j0Nr6nDbNd4STrqvKOQKBgQDNn/OPA3Ks54pbqD/mwrWpk4h4Yik5qqwD\nOQL2+0s5aI4TtEeU0kI74u2u/AMlVqch0e/vPDE2oiu4Ri9MQKFOed2Vb/IeexyZ\nK/X7c1xIa3wDG4Fpp7Zf44ENlz72m3T4AQs0/QSFVepHH5o2eSbt+zNbvTXQBFPE\nI/XVkqvsaQKBgQD0AUEyq4pSC/3M9959g3yZBtKExfjStbhVcNPL1hMIC4r7PyRn\nzsYJWaZarDQanvkyadu6XV6yZbCRrJ+NBQ2QusFHg7IsYHNi8ykfyyEXWUe5EwRN\nO4+xsaeTqsFR5ffvoFR1zYXUE9dzNKQubtI+EXYB/PtmMKY2cclpRpRPAQKBgQCC\nRUPY5yZPxKEzUHSrjc/pjz52hjn3/tjbcdMfBjWyOTtJ4H05G7w6lJpgseiMT0mz\nc32+4zOKmVN5e32boZaQUUTA2BtclEKaB+/fTka42t/GWDa06TvxpACuy5NoSTTh\nqkefWmzyzGuQg8T/neG8A9mV1VmyYAtxyBaHKFUjgQKBgHsMG7xOZGTFR6ob68JS\nPmjeOR3kKC8vIRd7usWi1w9ZvP3cY1s5hgyLyUjc+KN+eeFLP20nugHzPklDF2/v\nlwrYh8BdJzEAIOXBNI5PTZ/fLey6eM1Ep5cd7oflOMLNHSyo5tawr0c14MLYvsw/\n3g4txWFg8v4ibK/NafsFrdSf\n-----END PRIVATE KEY-----\n";`4616`
      var pClaim = {
        "aud": "https://oauth2.googleapis.com/token",
        "scope": "https://www.googleapis.com/auth/admin.directory.user",
        "iss": "idos-425@intranet-261309.iam.gserviceaccount.com",
        "exp": Math.floor(Date.now()/1000)+3600,
        "iat": Math.floor(Date.now()/1000),
        "sub": "daniel.eriksson@idealofsweden.com"
        };
      var sClaim = JSON.stringify(pClaim);

      var sJWS = KJUR.jws.JWS.sign(null, sHeader, sClaim, key);
      axios({
        url: 'https://oauth2.googleapis.com/token',
        method: 'post',
        data: {
                 'grant_type': 'urn:ietf:params:oauth:grant-type:jwt-bearer',
                 'assertion': sJWS
              }
          }).then(response => {
            //this.tokki.push(response.data)
              var token = response.data.access_token //$cookies.get("aW50cmFuZXRjcmVkZW50aWFscw==").token
      //var token = $cookies.get("aW50cmFuZXRjcmVkZW50aWFscw==").token
      axios({
        url: 'https://www.googleapis.com/admin/directory/v1/users',
        method: 'get',
        headers: {
             'Accept': 'application/json',
             'Authorization': 'Bearer ' + token,
        },
        params: {
                 'viewType': 'domain_public',
                 'domain': 'idealofsweden.com',
                 'projection': 'full',
                 'maxResults': 500,
                 'key': '60a0a2c29f86e61a0f9b3d781ddf34c4be166d2d'
                }
      }).then(response => {
        let curr = new Date
        let week = []

        for (let i = 1; i <= 7; i++) {
          let first = curr.getDate() - curr.getDay() + i
          let day = new Date(curr.setDate(first)).toISOString().slice(5, 10)
          week.push(day)
        }
        for (var i = 0; i < response.data.users.length; i++) {
          var user = response.data.users[i]
          if (user.hasOwnProperty('customSchemas')) {
            if (user.customSchemas.Work.hasOwnProperty('DOB')) {
              if (week.includes(user.customSchemas.Work.DOB.slice(5))) {
                var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                var d = new Date(user.customSchemas.Work.DOB).toISOString().slice(5, 10);
                var v = new Date().toISOString().slice(0,5)
                d = v + d
                var dat = {
                  'id': user.id,
                  'name': user.name.fullName,
                  'day': days[new Date(d).getDay()],
                  'date': d
                }
                this.bDays.push(dat)
              }
            }
          }
        }
        this.bDays.sort((a, b) => (b.day < a.day) ? 1 : -1)
      })
      .catch(function (error) {
        console.log(error)
      });
      })



      db.collection('intranetNewJoiners').get().then(querySnapshot => {
      var ad = []
        this.newJoin = ad
        querySnapshot.forEach((doc) => {
          if (doc.id != 'stat') {
            var last = doc.data().Employment_journey.length - 1;
            var d = {
              'name': doc.data().AC_First_names + ' ' + doc.data().AB_Surname,
              'title': doc.data().Employment_journey[last].Role,
              'dep': doc.data().DC_Team,
              'location': doc.data().DD_Location
            }
            this.newJoin.push(d)
          }
        });
      });
    axios({
        url: 'https://api.workbuster.com/jobs/feed/idealofsweden',
        method: 'get',
      }).then(response =>  {
        response.data.jobs.forEach(job => {
        var data = {
              'id': job.id,
              'title': job.title,
              'desc': job.description_short,
              'deadline': job.last_application_date,
              'pub': job.published_first_date,
              'responsible': job.responsible.name,
              'location': job.location.name,
              'image': job.image,
              'apply_url': job.apply_url,
              'open': false
            }
            this.jobs.push(data)
        })
        this.jobs.sort((a, b) => (b.pub > a.pub) ? 1 : -1)
      })
      db.collection('appreciation').orderBy("time", "desc").get().then(querySnapshot => {
      var ap = []
        this.appreciations = ap
        querySnapshot.forEach((doc) => {
          var d = {
            'id': doc.id,
            'text': doc.data().text,
            'category': doc.data().category,
            'mention': doc.data().mention
          }
          this.appreciations.push(d)
        });
      });
      db.collection('posts').orderBy("timestamp", "desc").get().then(querySnapshot => {
        querySnapshot.forEach((doc) => {
          var likes = doc.data().likes.length
          var commentss = doc.data().comments.length
          var data = {
            'id': doc.id,
            'title': doc.data().title,
            'broadtext': doc.data().broadtext,
            'photo': doc.data().photo,
            'author': doc.data().author,
            'authorpic': doc.data().authorpic,
            'time': doc.data().time,
            'likes': doc.data().likes,
            'likess': likes,
            'comments': doc.data().comments,
            'commentss': commentss,
            'showComments': doc.data().showComments,
            'date': doc.data().date
          }
          this.posts.push(data)
        });
      });
      db.collection('instaposts').orderBy("timestamp", "desc").get().then(querySnapshot => {
        querySnapshot.forEach((doc) => {
          var data = {
            'id': doc.id,
            'title': doc.data().title,
            'broadtext': doc.data().broadtext,
            'photo': doc.data().photo,
            'author': doc.data().author,
            'authorpic': doc.data().authorpic,
            'time': doc.data().time,
            'date': doc.data().date
          }
          this.instaposts.push(data)
        });
      });
      db.collection('comments').orderBy("timestamp", "asc").get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          var comdata = {
            'id': doc.id,
            'author': doc.data().author,
            'authorpic': doc.data().authorPic,
            'postId': doc.data().postId,
            'text': doc.data().text,
          }
          this.comments.push(comdata)
        });
      });
    },
    /*
    computed: {
      orderedPosts: function () {
        return .orderBy(this.posts, 'timestamp')
      }
    },
    */
    methods: {
      like: function (id, likes, likess) {
        if (likes.includes(this.name)) {

        }
        else {
          likes.push(this.name)
          var upLike = likess + 1
          likess = upLike
          db.collection('posts').doc(id).update({
            'likes': likes
        })
      }
    },
    comment: function (id, comments, commentss) {
          var now = Math.floor(Date.now() / 1000);
          db.collection("comments").add({
              postId: id,
              author: this.name,
              authorPic: this.photo,
              text: this.Com,
              timestamp: now,
          })
          .then((data) => {
            this.comments = []
            this.com = ''
            db.collection('comments').orderBy("timestamp", "asc").get().then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                var comdata = {
                  'id': doc.id,
                  'author': doc.data().author,
                  'authorpic': doc.data().authorPic,
                  'postId': doc.data().postId,
                  'text': doc.data().text,
                }
                this.comments.push(comdata)
              });
            });
          })
          .then(docRef => {
            this.$refs.form.reset()
          })
          .catch(function(error) {
              console.error("Error writing document: ", error);
          });
    }
  }
}
</script>

<style>
  .v-sheet--offset {
    top: -24px;
    position: relative;
  }
  .floatBR {
    position: absolute;
    bottom: 0;
    right: 0;
  }
</style>
