<template>
  <v-container class="justify-center">
    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfq2Qv3CVUwugz0h8_eFF7OvSbb_oKTeuVHIByAw5W0xbotaA/viewform?embedded=true" width="640" height="1605" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
  </v-container>
</template>


<script>
export default {
  data: () => ({
    date: null,
  })
}
</script>
