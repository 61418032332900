<template>
  <v-app id="inspire">
    <admnew></admnew>
  </v-app>
</template>

<script>
import admnew from '../components/admnew.vue'

export default {
  name: 'adm',
  components: {
    admnew
  },
  data () {
    return {
    }
  }
}
</script>
