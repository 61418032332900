<template>
  <div>
  <v-tabs
    background-color="transparent"
    grow
  >
    <v-tab>
      Navision
    </v-tab>
  </v-tabs>


  <v-tabs-items>
  <v-tab-item>
    <p>test</p>
  </v-tab-item>
</v-tabs-items>
<embed src="https://form.asana.com?hash=e43766d0ab44fd8846eea92a3df6bdd113e654dac7b70a48be8f2c6829925c04&id=1161411561916728"/>
</div>
</template>

<script>
import axios from 'axios'
import firebase from 'firebase'
  export default {
    name: 'requests',
    data () {
      return {

      }
    }
  }
</script>
