<template>
  <v-container grid-list-md text-xs-center>
    <v-alert
      :value="alert"
      type="success"
      dismissible
    >
      Your post was succesfully added!
    </v-alert>
    <v-alert
      :value="erroralert"
      type="error"
      dismissible
    >
      There was a problem uploading you post :/
    </v-alert>
    <v-dialog
      v-model="dialog"
    >
      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >
          Edit Post
        </v-card-title>
        <v-text-field
          class="mx-5"
          v-model="activeEdit.title"
          label="Title"
          :placeholder="activeEdit.title"
          required
        ></v-text-field>
        <quill-editor ref="editTextEditor"
          v-model="activeEdit.broadtext"
          :options="editorOption">
        </quill-editor>
        <v-img
        max-height="500"
        contain
        v-if="activeEdit.photo"
        :src="activeEdit.photo"
        >
        <v-btn text icon @click="activeEdit.photo = ''">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        </v-img>
        <div v-else>
          <v-btn raised @click="onPickEditFile">
            Upload Image
          </v-btn>
          <input type="file" name="" value="" style="display:none" accept="image/*" @change="onEditFilePicked" ref="fileinputedit">
        </div>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="updatePost(activeEdit);dialog = false; activeEdit = {}"
          >
            Save
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="dialog = false; activeEdit = {}"
          >
            cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout row wrap>
      <v-flex xs7>
        <div class="display-1">New Post</div>
          <v-form
            @submit.prevent="savePost"
            ref="form"
          >
            <v-text-field
              v-model="title"
              label="Title"
              required
            ></v-text-field>
      <!--      <v-text-field
              v-model="time"
              label="Time"
              v-bind:value="this.time"
            ></v-text-field>
            <v-text-field
              v-model="date"
              label="Date"
              v-bind:value="this.date"
            ></v-text-field>-->
            <quill-editor ref="myTextEditor"
              v-model="content"
              :options="editorOption">
            </quill-editor>
            <vue-upload-multiple-image
              @upload-success="uploadImageSuccess"
              @before-remove="beforeRemove"
              @edit-image="editImage"
              :data-images="images"
              idUpload="myIdUpload"
              editUpload="myIdEdit"
            ></vue-upload-multiple-image>
            <input type="file" name="" value="" style="display:none" accept="image/*" @change="onFilePicked" ref="fileinput">
            <v-img v-if="imgageUrl != ''" :src="imgageUrl"></v-img>
            <v-btn
              type="submit"
              color="success"
            >
              Post
            </v-btn>

          </v-form>
      </v-flex>
      <v-flex xs5>
        <div class="display-1 mb-5 ml-5">My Posts</div>
        <v-card
        v-for="post in myPosts"  :key="post.id"
          class="mx-auto"
          max-width="400"
        >
          <v-card-title>
            <span class="headline font-weight-light">{{ post.title }}</span>
          </v-card-title>

          <v-card-text class="body-2" v-html="post.broadtext.substring(0,100)+'...'">
          </v-card-text>
          <v-card-actions>
            <v-btn text icon @click="startEdit(post)"><v-icon>mdi-pen</v-icon></v-btn>
            <v-btn text icon @click="delPost(post.id)"><v-icon>mdi-delete</v-icon></v-btn>
            <v-spacer></v-spacer>
            <div class="body-1">{{ post.date }}</div>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
function stripHtml(html)
{
   var tmp = document.createElement("DIV");
   tmp.innerHTML = html;
   return tmp.innerText;
}
function dataURLtoFile(dataurl, filename) {

       var arr = dataurl.split(','),
           mime = arr[0].match(/:(.*?);/)[1],
           bstr = atob(arr[1]),
           n = bstr.length,
           u8arr = new Uint8Array(n);

       while(n--){
           u8arr[n] = bstr.charCodeAt(n);
       }

       return new File([u8arr], filename, {type:mime});
   };
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import hljs from 'highlight.js'
import { quillEditor } from 'vue-quill-editor'
import * as firebase from 'firebase'
import VueUploadMultipleImage from 'vue-upload-multiple-image'
import axios from 'axios'

var db = firebase.firestore();

var fid = null

    export default {
      name: 'Posts',
      components: {
        quillEditor,
        VueUploadMultipleImage
      },
      data () {
        return {
          title: null,
          content: "",
          imgageUrl: '',
          image: null,
          images: [],
          toBeimages: [],
          editimgageUrl: '',
          editimage: null,
          alert: false,
          dialog: false,
          erroralert: false,
          photo: $cookies.get("aW50cmFuZXRjcmVkZW50aWFscw==").picture,
          userId: $cookies.get("aW50cmFuZXRjcmVkZW50aWFscw==").id,
          name: $cookies.get("aW50cmFuZXRjcmVkZW50aWFscw==").fullname,
          email: $cookies.get("aW50cmFuZXRjcmVkZW50aWFscw==").email,
          user: {},
          activeEdit: {},
          myPosts: [],
          editorOption: {
            modules: {
              toolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                ['blockquote', 'code-block'],
                [{ 'header': 1 }, { 'header': 2 }],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'script': 'sub' }, { 'script': 'super' }],
                [{ 'indent': '-1' }, { 'indent': '+1' }],
                [{ 'direction': 'rtl' }],
                [{ 'size': ['small', false, 'large', 'huge'] }],
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                [{ 'font': [] }],
                [{ 'color': [] }, { 'background': [] }],
                [{ 'align': [] }],
                ['clean'],
                ['link','video']
              ],
              syntax: {
                highlight: text => hljs.highlightAuto(text).value
              }
            }
          }
        }
      },
      created () {
        db.collection('posts').where("author", "==", this.name).orderBy("timestamp", "desc").get().then(querySnapshot => {
          querySnapshot.forEach((doc) => {
            console.log(doc.data())
            var likes = doc.data().likes.length
            var commentss = doc.data().comments.length
            var data = {
              'id': doc.id,
              'title': doc.data().title,
              'broadtext': doc.data().broadtext,
              'photo': doc.data().photo,
              'author': doc.data().author,
              'authorpic': doc.data().authorpic,
              'time': doc.data().time,
              'likes': doc.data().likes,
              'likess': likes,
              'comments': doc.data().comments,
              'commentss': commentss,
              'showComments': doc.data().showComments,
              'date': doc.data().date
            }
            this.myPosts.push(data)
          });
        });
      },
      methods: {

        uploadImageSuccess(formData, index, fileList) {
          this.toBeimages = fileList
          console.log(this.images)
          console.log(fileList)
          this.toBeimages.forEach((item, i) => {
            if (item.path.startsWith('data:image/png')) {
              item.type = "image/png"
            }
            else if (item.path.startsWith('data:image/jpg')) {
              item.type = "image/jpg"
            }
            else if (item.path.startsWith('data:image/jpeg')) {
              item.type = "image/jpeg"
            }
            else if (item.path.startsWith('data:video/mp4')) {
              item.type = "video/mp4"
            }
            else if (item.path.startsWith('data:image/gif')) {
              item.type = "image/gif"
            }
            else if (item.path.startsWith('data:video/webp')) {
              item.type = "video/webp"
            }
          });

          // Upload image api
          // axios.post('http://your-url-upload', formData).then(response => {
          //   console.log(response)
          // })
        },
        beforeRemove (index, done, fileList) {
          console.log('index', index, fileList)
          var r = confirm("remove image")
          if (r == true) {
            done()
          } else {
          }
        },
        editImage (formData, index, fileList) {
          console.log('edit data', formData, index, fileList)
        },
        savePost () {
          var links = []
          var todayDate = new Date().toISOString().slice(0,10);
          var cDate = new Date();
          var h = cDate.getHours();
          var m = cDate.getMinutes();
          var cTime = h + ":" + m;
          var now = Math.floor(Date.now() / 1000);
          var randomNum = Math.floor(Math.random() * 2000);

          if (m < 10) {
            m = '0' + m.toString()
          }

          if (this.images === null) {
          db.collection('posts').add({
            title: this.title,
            broadtext: this.content,
            author: this.name,
            authorpic: this.photo,
            time: cTime,
            date: todayDate,
            timestamp: now,
            likes: [],
            comments: [],
            showComments: randomNum,
          })
          .then(docRef => {
                axios({
                  url: 'https://hooks.zapier.com/hooks/catch/5015474/omzchd4/',
                  method: 'post',
                  params: {
                           'title': this.title,
                           'slackText': stripHtml(this.content).slice(0, 150),
                           'author': this.name,
                           'authorpic': this.photo,
                          }
                }).then(response => {
                  this.alert = true
                  this.$refs.form.reset()
              //    this.$router.push({ path: `/` })
            })
          })
          .catch(error => {
            console.error('Error adding post: ', error)
            this.erroralert = true

          })
          }
          else {
            db.collection('posts').add({
              title: this.title,
              broadtext: this.content,
              author: this.name,
              authorpic: this.photo,
              time: cTime,
              photo: [],
              date: todayDate,
              timestamp: now,
              likes: [],
              comments: [],
              showComments: randomNum,
            })
            .then((data) => {
              const id = data.id
              return id
            })
            .then(id => {
              var postId = id

              this.toBeimages.forEach(el =>
                firebase.storage().ref('postpics/' + el.name).putString(el.path, 'data_url').then(function(snapshot, fireId) {
                  firebase.storage().ref('postpics/' + el.name).getDownloadURL().then(url => {
                    links.push(url)
                    db.collection('posts').doc(postId).update({
                       'photo': firebase.firestore.FieldValue.arrayUnion(url)
                   })
                  })
                })
              )

            })
            .then(docRef => {
                  axios({
                    url: 'https://hooks.zapier.com/hooks/catch/5015474/omzchd4/',
                    method: 'post',
                    params: {
                             'title': this.title,
                             'slackText': stripHtml(this.content).slice(0, 150),
                             'author': this.name,
                             'authorpic': this.photo,
                            }
                  }).then(response => {
                    this.alert = true
                    this.$refs.form.reset()
                //    this.$router.push({ path: `/` })
              })
            })
            .catch(error => {
              console.error('Error adding post: ', error)
              this.erroralert = true

            })
          }
        },
        delPost(id) {
          if(confirm ('Are you sure?')) {
            db.collection("posts").doc(id).delete().then(function() {
            }).catch(function(error) {
                console.error("Error removing document: ", error);
            });
          }
        },
        updatePost() {
          db.collection("posts").doc(this.activeEdit.id).update({
            title: this.activeEdit.title,
            photo: this.activeEdit.photo,
            broadtext: this.activeEdit.broadtext,
            })
            .then(() => {
                console.log("Document successfully updated!");

            })
            .catch(function(error) {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
            });
          },
        onPickFile () {
          this.$refs.fileinput.click()
        },
        onPickEditFile () {
          this.$refs.fileinputedit.click()
        },
        startEdit (post) {
          this.activeEdit = post
          this.dialog = true
        },
        onFilePicked (event) {
          const files = event.target.files
          let filename = files[0].name;
          if (filename.lastIndexOf('.') <= 0) {
            return alert('Please pick a valid file')
          }
          const fileReader = new FileReader()
          fileReader.addEventListener('load', () => {
            this.imgageUrl = fileReader.result
          })
          fileReader.readAsDataURL(files[0])
          this.image = files[0]
        },
        onEditFilePicked (event) {
          const files = event.target.files
          let filename = files[0].name;
          if (filename.lastIndexOf('.') <= 0) {
            return alert('Please pick a valid file')
          }
          const fileReader = new FileReader()
          fileReader.addEventListener('load', () => {
            this.activeEdit.photo = fileReader.result
          })
          fileReader.readAsDataURL(files[0])
          this.activeEdit.photo = files[0]
        }
      },
      computed: {
        editor() {
          return this.$refs.myTextEditor.quill
        },
        contentCode() {
          return hljs.highlightAuto(this.content).value
        }
      },
      mounted() {
        //console.log('this is my editor', this.editor.root.innerHTML)
        setTimeout(() => {
          this.content = ``
        }, 1300)
      }
    }
</script>
