<template>
  <v-container class="justify-center">
    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdC7asWreETgEfjIvCqbfp_icNVxyNwoqsZIPEwO6jp0ZGxGQ/viewform?embedded=true" width="640" height="1838" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    date: null,
  })
}
</script>
