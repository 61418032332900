<template>
  <div>
    <v-layout wrap>
      <v-flex md8 mt-8>
        <v-sheet height="64">
          <v-toolbar flat color="white">
            <v-btn outlined class="mr-4" @click="setToday">
              Today
            </v-btn>
            <v-btn fab small @click="prev">
              <v-icon small>fas fa-chevron-left</i></v-icon>
            </v-btn>
            <v-btn fab small @click="next">
              <v-icon small>fas fa-chevron-right</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-toolbar-title>{{ focus }}</v-toolbar-title>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="events"
            :event-color="getEventColor"
            :event-margin-bottom="3"
            :now="today"
            type="month"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
            @change="updateRange"
          ></v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            full-width
            offset-x
          >
            <v-card
              min-width="350px"
            >
              <v-toolbar
                :color="selectedEvent.color"
                dark
              >
                <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn text icon color="white" @click="delet(selectedEvent)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-toolbar>
              <v-container v-if="selectedEvent.type === 'other'" style="max-width:300px">
                {{ selectedEvent.details }}
              </v-container>

              <v-container v-if="selectedEvent.type === 'massage'">
                <ul>
                  <li v-for="(entry, index) in selectedEvent.entries">{{ entry.title }}: {{ entry.Assignee }}</li>
                </ul>
              </v-container>
              <v-container v-if="selectedEvent.type === 'workout'">
                <div class="body-2">
                  <p>This workout will be held {{ selectedEvent.time }}</p>
                  <p>Responsible is {{ selectedEvent.resp }}</p>
                  <p>{{ selectedEvent.avseats }}/{{ selectedEvent.seats }} spots available. This is the attendee list:</p>
                  <ul>
                    <li v-for="(atte, index) in selectedEvent.attendees"  :key="index">{{ atte }}</li>
                  </ul>
                </div>
              </v-container>

            </v-card>
          </v-menu>
        </v-sheet>
      </v-flex>



      <v-flex md4 class="mt-8 pl-5 pr-5">

        <v-select
          v-model="selectReg"
          :items="its"
          label="Choose activity"
          solo
        ></v-select>

        <!-- MASSAGE -->

      <v-form
      v-if="this.selectReg === 'massage'"
      @submit.prevent="addMas"
      ref="form">
      <v-layout wrap>
        <v-container>
      <v-flex md12>
        <v-date-picker v-model="picker"></v-date-picker>
      </v-flex>
      <v-flex md12>
      <v-text-field
        label="Email to the masseuse"
        placeholder=""
        outlined
        :rules="emailRules"
        v-model="masEmail"
        class="mt-5"
        required
      ></v-text-field>
      <v-select
        v-model="office"
        :items="items"
        menu-props="auto"
        hide-details
        label="Office"
        single-line
        required
      ></v-select>
      <v-btn class="mt-5" color="success" Type="submit">Schedule massage</v-btn>
    </v-flex>
  </v-container>
  </v-layout>
    </v-form>
    <!-- WORKOUT -->
    <v-form
    v-if="this.selectReg === 'workout'"
    @submit.prevent="addWok"
    ref="form">
<v-layout wrap>
  <v-flex md12>
    <v-text-field
      label="Email to the workout organiser"
      placeholder=""
      outlined
      :rules="emailRules"
      v-model="wokEmail"
      class="mt-5"
      required
    ></v-text-field>
    <v-text-field
      label="Titel of workout"
      placeholder=""
      outlined
      v-model="wokTit"
      class="mt-5"
      required
    ></v-text-field>
  </v-flex>
  <v-flex md6>
    <v-dialog
    ref="dialog"
    v-model="modal"
    width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="picker"
        label="Date"
        prepend-icon="event"
        readonly
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker v-model="picker" scrollable>
    </v-date-picker>
  </v-dialog>
  </v-flex>
  <v-flex md6>
    <v-dialog
          ref="dialog"
          v-model="modaltime"
          width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="e7"
              label="Start time"
              prepend-icon="access_time"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
          format="24hr"
            v-if="modaltime"
            v-model="e7"
            full-width
          >
          </v-time-picker>
        </v-dialog>
  </v-flex>
  <v-flex md6>
    <v-select
      class="mt-10"
      v-model="office"
      :items="items"
      menu-props="auto"
      hide-details
      label="Office"
      single-line
      required
    ></v-select>
  </v-flex>
  <v-flex md6>
    <v-subheader class="pl-0">Number of available spots</v-subheader>
    <v-slider
      class="mt-5"
      v-model="slider"
      thumb-label="always"
    ></v-slider>
  </v-flex>
    <v-btn class="mt-5" color="success" Type="submit">Schedule workout</v-btn>
</v-layout>
  </v-form>

  <!-- OTHER -->
  <v-form
  v-if="this.selectReg === 'other'"
  @submit.prevent="addOtr"
  ref="form">

<v-layout wrap>
  <v-flex md12>
    <v-text-field
      label="Event Title"
      placeholder=""
      outlined
      v-model="otrtit"
      class="mt-2"
      required
    ></v-text-field>
  </v-flex>
  <v-flex md12>
    <v-textarea
      outlined
      required
      v-model="otrdesc"
      name="input-7-4"
      label="Event Description"
    ></v-textarea>
  </v-flex>
<v-flex md6>
  <v-dialog
  ref="dialog"
  v-model="modal"
  width="290px"
>
  <template v-slot:activator="{ on }">
    <v-text-field
      v-model="picker"
      label="Date"
      prepend-icon="event"
      readonly
      v-on="on"
    ></v-text-field>
  </template>
  <v-date-picker v-model="picker" scrollable>
  </v-date-picker>
</v-dialog>
</v-flex>


<v-flex md6>
  <v-dialog
        ref="dialog"
        v-model="modaltime"
        width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="e7"
            label="Start time"
            prepend-icon="access_time"
            readonly
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
        format="24hr"
          v-if="modaltime"
          v-model="e7"
          full-width
        >
        </v-time-picker>
      </v-dialog>
</v-flex>
<v-flex md6>
  <v-dialog
        ref="dialog"
        v-model="modaltime2"
        width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="e8"
            label="Stop time"
            prepend-icon="access_time"
            readonly
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
        format="24hr"
          v-if="modaltime2"
          v-model="e8"
          full-width
        >
        </v-time-picker>
      </v-dialog>
</v-flex>


<v-flex md6>
  <v-dialog
        ref="dialog"
        v-model="modalcol"
        width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="otrcol"
            label="Event color"
            prepend-icon="format_paint"
            readonly
            v-on="on"
          ></v-text-field>
        </template>
        <v-color-picker
          v-model="otrcol"
        >
        </v-color-picker>
      </v-dialog>
</v-flex>
<v-flex md6>
  <v-select
    v-model="office"
    :items="items"
    menu-props="auto"
    hide-details
    label="Office"
    single-line
    required
  ></v-select>
</v-flex>
<v-flex md6 >
  <v-btn class="mt-5 ml-10" color="success" Type="submit">Schedule Event</v-btn>
</v-flex>
</v-layout>
</v-form>
  </v-flex>
</v-layout>
  </div>
</template>


<script>
import firebase from 'firebase'
import axios from 'axios'

var db = firebase.firestore();
  export default {
    data () {
      return {
        modal: false,
        otrtit: null,
        otrdesc: null,
        otrcol: '#041A36',
        modaltime: false,
        modaltime2: false,
        selectReg: '',
        slider: 20,
        alignments: {},
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        picker: new Date().toISOString().substr(0, 10),
        masEmail: null,
        wokEmail: null,
        wokTit: null,
        office: null,
        e7: null,
        e8: null,
        items: [
        { text: 'Norrköping', value: 'nrkpg' },
        { text: 'Stockholm', value: 'sthlm' },
      ],
      its: ['massage','workout','other'],
      today: '2019-10-01',
      focus: '2019-10-01',
      radioGroup: null,
      showSnack: false,
      myEvent: false,
      text: null,
      type: 'month',
      typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day',
        '4day': '4 Days',
      },
      start: null,
      end: null,
      selectedEvent: {},
      skick: {

      },
      selectedElement: null,
      selectedOpen: false,
      events: [],
      }
    },
    created () {
      this.today = new Date().toISOString().slice(0,10);
      this.focus = this.today


      db.collection('calendar').get().then(querySnapshot => {

        querySnapshot.forEach((doc) => {

                  //        WORKOUT IMPORT

          if (doc.data().type === "workout") {
            var startTime = new Date(doc.data().startTime*1000).toISOString().slice(0,10)
            var avseats =  doc.data().seats - doc.data().attendees.length
            var id = doc.id
            var data = {
              'time': doc.data().time,
              'id': id,
              'type': doc.data().type,
              'resp': doc.data().wokemail,
              'location': doc.data().location,
              'start': startTime,
              'details': doc.data().name,
              'name': doc.data().name + " || " +  doc.data().title,
              'color': 'green',
              'avseats': avseats,
              'seats': doc.data().seats,
              'attendees':doc.data().attendees,
            }
            this.events.push(data)
          }
                            //        OTHER IMPORT

                    if (doc.data().type === "other") {
                      var startTime = new Date(doc.data().startTime*1000).toISOString().slice(0,10)
                      var stopTime = new Date(doc.data().stopTime*1000).toISOString().slice(0,10)
                      var id = doc.id
                      var data = {
                        'startdisp': doc.data().start,
                        'stopdisp': doc.data().stop,
                        'id': id,
                        'type': 'other',
                        'location': doc.data().location,
                        'start': startTime,
                        'stop': stopTime,
                        'details': doc.data().desc,
                        'name': doc.data().name,
                        'color': doc.data().color
                      }
                      this.events.push(data)
                    }

                            //        MASSAGE IMPORT


          if (doc.data().type === "massage") {
            var startTime = new Date(doc.data().startTime*1000).toISOString().slice(0,10)
            var id = doc.id
            var info = []
            Object.values(doc.data()).forEach(key => {
              if (key.title) {
                info.push(key)
              }
            });
            var data = {
              'time': doc.data().time,
              'id': id,
              'type': 'massage',
              'location': doc.data().location,
              'start': startTime,
              'details': 'Massage',
              'name': 'Massage ' + doc.data().location,
              'color': 'blue',
              'entries':info.sort((a, b) => (a.indx < b.indx) ? 1 : -1)
            }
            this.events.push(data)

          }
          var startTime = new Date(doc.data().startTime*1000).toISOString().slice(0,10)
          });
      });
    },
    methods: {
        delet (even) {
          if(confirm ('Are you sure?')) {
            db.collection("calendar").doc(even.id).delete().then(function() {
              window.location.reload()
            }).catch(function(error) {
                console.error("Error removing document: ", error);
            });
          }
        },
        viewDay ({ date }) {
          this.focus = date
          this.type = 'day'
        },
        getEventColor (event) {
          return event.color
        },
        setToday () {
          this.focus = this.today
        },
        prev () {
          this.$refs.calendar.prev()
        },
        next () {
          this.$refs.calendar.next()
        },
        showEvent ({ nativeEvent, event }) {
          const open = () => {
            this.selectedEvent = event
            this.selectedElement = nativeEvent.target
            setTimeout(() => this.selectedOpen = true, 10)
          }

          if (this.selectedOpen) {
            this.selectedOpen = false
            setTimeout(open, 10)
          } else {
            open()
          }

          nativeEvent.stopPropagation()
        },
        updateRange ({ start, end }) {
          // You could load events from an outside source (like database) now that we have the start and end dates on the calendar
          this.start = start
          this.end = end
        },
        nth (d) {
          return d > 3 && d < 21
            ? 'th'
            : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10]
        },
      addMas () {
        console.log(this.picker + "T11:00:00")

      var start = new Date(this.picker).getTime() / 1000
      var stop = (new Date(this.picker).getTime() / 1000) + 3600

      db.collection("calendar").add({
        'time': this.picker,
        'type': 'massage',
        'location': this.office,
        'startTime': start,
        'StopTime': stop,
        'name': 'Massage ' + this.office,
        'massemail': this.masEmail,
        '8:00': {
          'Assignee': "",
          'start': this.picker + "T08:00:00",
          'stop': this.picker + "T08:30:00",
          'indx': 1,
          'title': '8:00'
        },
        '8:30': {
          'Assignee': "",
          'start': this.picker + "T08:30:00",
          'stop': this.picker + "T09:00:00",
          'indx': 2,
          'title': '8:30'
        },
        '9:00': {
          'Assignee': "",
          'start': this.picker + "T09:00:00",
          'stop': this.picker + "T09:30:00",
          'indx': 3,
          'title': '9:00'
        },
        '10:00': {
          'Assignee': "",
          'start': this.picker + "T10:00:00",
          'stop': this.picker + "T10:30:00",
          'indx': 4,
          'title': '10:00'
        },
        '10:30': {
          'Assignee': "",
          'start': this.picker + "T10:30:00",
          'stop': this.picker + "T11:00:00",
          'indx': 5,
          'title': '10:30'
        },
        '11:00': {
          'Assignee': "",
          'start': this.picker + "T11:00:00",
          'stop': this.picker + "T11:30:00",
          'indx': 6,
          'title': '11:00'
        },
        '11:30': {
          'Assignee': "",
          'start': this.picker + "T11:30:00",
          'stop': this.picker + "T12:00:00",
          'indx': 7,
          'title': '11:30'
        },
        '13:00': {
          'Assignee': "",
          'start': this.picker + "T13:00:00",
          'stop': this.picker + "T13:30:00",
          'indx': 8,
          'title': '13:00'
        },
        '13:30': {
          'Assignee': "",
          'start': this.picker + "T13:30:00",
          'stop': this.picker + "T14:00:00",
          'indx': 9,
          'title': '13:30'
        },
        '14:00': {
          'Assignee': "",
          'start': this.picker + "T14:00:00",
          'stop': this.picker + "T14:30:00",
          'indx': 10,
          'title': '14:00'
        },
        '15:00': {
          'Assignee': "",
          'start': this.picker + "T15:00:00",
          'stop': this.picker + "T15:30:00",
          'indx': 11,
          'title': '15:00'
        },
        '15:30': {
          'Assignee': "",
          'start': this.picker + "T15:30:00",
          'stop': this.picker + "T16:00:00",
          'indx': 12,
          'title': '15:30'
        },
        }).then(() => {
          axios({
            url: 'https://hooks.zapier.com/hooks/catch/5015474/odtk9jz/',
            method: 'post',
            params: {
                'type': 'massage',
                'startTime': start,
                'location': this.office,
                'time': this.picker
                },
          }).then(response =>  {
                window.location.reload()
                this.$refs.form.reset()
          })
      })

    },
    addWok () {
    var str = this.picker + 'T' + this.e7;
    var start = new Date(str).getTime() / 1000
    var stop = (start + 3600)
    var time = parseInt(this.e7.substring(0, 2)) + 1
    if (time <= 9) {
      time = '0' + time.toString()
    } else {
      time = time.toString()
    }
    var st = this.picker + 'T' + this.e7 + ':00';
    var sp = this.picker + 'T' + time + ':00:00'
    db.collection("calendar").add({
      'time': this.e7,
      'type': 'workout',
      'title': this.wokTit,
      'location': this.office,
      'startTime': start,
      'stopTime': stop,
      'str': st,
      'stp': sp,
      'seats': this.slider,
      'attendees': [],
      'name': 'Workout ' + this.office,
      'wokemail': this.wokEmail,
      }).then(() => {
        axios({
          url: 'https://hooks.zapier.com/hooks/catch/5015474/odtk9jz/',
          method: 'post',
          headers: {
               'Content-Type': 'application/x-www-form-urlencoded',
          },
          params: {
              'name': this.wokTit,
              'type': 'workout',
              'location': this.office,
              'time': this.e7,
              'startTime': start
              },
        }).then(response =>  {
              window.location.reload()
              this.$refs.form.reset()
        })
    })
  },
  addOtr () {
  var str = this.picker + 'T' + this.e7;
  var stp = this.picker + 'T' + this.e8;
  var start = new Date(str).getTime() / 1000
  var stop = new Date(stp).getTime() / 1000

  db.collection("calendar").add({
    'start': this.e7,
    'stop': this.e8,
    'type': 'other',
    'location': this.office,
    'startTime': start,
    'stopTime': stop,
    'name': this.otrtit,
    'desc': this.otrdesc,
    'color': this.otrcol
    }).then(() => {
      axios({
        url: 'https://hooks.zapier.com/hooks/catch/5015474/odtk9jz/',
        method: 'post',
        params: {
            'name': this.otrtit,
            'desc': this.otrdesc,
            'type': 'other',
            'location': this.office,
            'startTime': start,
            'stopTime': stop,
            },
      }).then(response =>  {
            window.location.reload()
      })
  })
}
  }
  }
</script>
