<template>
  <v-app id="inspire">
    <login></login>
  </v-app>
</template>

<script>
import login from '../components/login'

export default {
  components: {
    login
},
  data() {
    return {
    }
  }
}
</script>
