<template>
  <v-app>
    <v-row no-gutters>
      <v-col
        cols="12"
        sm="2"
      >
    <v-navigation-drawer
    absolute
    >
    <v-card
      class="mx-auto"
      max-width="200"
      flat
    >
      <v-card-text>
        <v-list>
          <v-list-item
            v-for="item in items"
            :key="item.id"
            v-on:click="activate(item.contentus, item.id)"
          >
            <v-list-item-content >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content >
              <div class="text-center">

                <v-dialog
                  v-model="dialog"
                  width="500"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="green"
                      dark
                      v-on="on"
                    >
                      Add doc
                    </v-btn>
                  </template>

                  <v-card>
                    <v-form
                    @submit.prevent="addDoc"
                    >
                    <v-card-title
                      class="headline grey lighten-2"
                      primary-title
                    >
                      Add Document
                    </v-card-title>
                    <v-container>

                    <v-text-field
                      solo
                      v-model="docnew"
                    ></v-text-field>
                  </v-container>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="green"
                        text
                        type="submit"
                      >
                        Add
                      </v-btn>

                    </v-card-actions>
                  </v-form>
                  </v-card>
                </v-dialog>

              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
    </v-navigation-drawer>
    </v-col>
    <v-col
      cols="12"
      sm="10"
    >
    <v-container fluid>



<div class="editor"  style="font-size: 15px">
  <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
    <div style="">
      <v-btn-toggle dark>
      <v-btn
        :class="{ 'is-active': isActive.bold() }"
        @click="commands.bold"
      >
      <v-icon px-2>fas fa-bold</v-icon>
    </v-btn>
      <v-btn
        :class="{ 'is-active': isActive.italic() }"
        @click="commands.italic"
      >
      <v-icon>fas fa-italic</v-icon>
    </v-btn>
      <v-btn
        :class="{ 'is-active': isActive.strike() }"
        @click="commands.strike"
      >
      <v-icon>fas fa-strikethrough</v-icon>
    </v-btn>
      <v-btn
        :class="{ 'is-active': isActive.underline() }"
        @click="commands.underline"
      >
      <v-icon>fas fa-underline</v-icon>
    </v-btn>
      <v-btn
        :class="{ 'is-active': isActive.heading({ level: 1 }) }"
        @click="commands.heading({ level: 1 })"
      >
        H1
      </v-btn>
      <v-btn
        :class="{ 'is-active': isActive.heading({ level: 2 }) }"
        @click="commands.heading({ level: 2 })"
      >
        H2
      </v-btn>
      <v-btn
        :class="{ 'is-active': isActive.heading({ level: 3 }) }"
        @click="commands.heading({ level: 3 })"
      >
        H3
      </v-btn>
      <v-btn
        :class="{ 'is-active': isActive.bullet_list() }"
        @click="commands.bullet_list"
      >
      <v-icon>format_list_bulleted</v-icon>
    </v-btn>
      <v-btn
        :class="{ 'is-active': isActive.ordered_list() }"
        @click="commands.ordered_list"
      >
      <v-icon>format_list_numbered</v-icon>
    </v-btn>
      <v-btn
        :class="{ 'is-active': isActive.code_block() }"
        @click="commands.code_block"
      >
      <v-icon>code</v-icon>
    </v-btn>
      <v-btn
        @click="commands.horizontal_rule"
      >
      <v-icon>maximize</v-icon>
    </v-btn>
      <v-btn
        @click="commands.undo"
      >
      <v-icon>undo</v-icon>
    </v-btn>
      <v-btn
        @click="commands.redo"
      >
      <v-icon>redo</v-icon>
    </v-btn>
    <v-btn @click="openModal(commands.iframe);">
    <v-icon>fab fa-youtube</v-icon>
    <path
             d="M511.3 213c-.1-10.3-1-23.3-2.4-39a354.4 354.4 0 0 0-6.1-42.1 66.4 66.4 0 0 0-19.9-35.1c-10.1-9.5-22-15-35.5-16.6-42.3-4.7-106.1-7.1-191.6-7.1-85.4 0-149.3 2.4-191.6 7.1a60.2 60.2 0 0 0-35.4 16.6 66.8 66.8 0 0 0-19.7 35.1A316 316 0 0 0 2.7 174a560.2 560.2 0 0 0-2.4 39 2430.9 2430.9 0 0 0 0 85.6 560 560 0 0 0 2.4 39c1.4 15.7 3.5 29.8 6.2 42.1a65.4 65.4 0 0 0 55.3 51.7c42.3 4.8 106.1 7.1 191.6 7.1s149.3-2.3 191.6-7.1c13.5-1.5 25.3-7 35.4-16.6a66.8 66.8 0 0 0 19.7-35c2.8-12.4 5-26.5 6.4-42.2 1.4-15.7 2.2-28.7 2.4-39a2450.8 2450.8 0 0 0 0-85.6zM357 271.2l-146.2 91.4a16.3 16.3 0 0 1-9.7 2.8c-2.9 0-5.8-.7-8.9-2.2a17 17 0 0 1-9.4-16V164.5a17 17 0 0 1 9.4-16 17.2 17.2 0 0 1 18.6.5L357 240.4c5.7 3.2 8.5 8.4 8.5 15.4s-2.8 12.2-8.5 15.4z"
           />
  </v-btn>
  <Modal ref="ytmodal" @onConfirm="addCommand" />
  </v-btn-toggle>
    </div>
  </editor-menu-bar>
</br>
<editor-menu-bar />
  <editor-content class="editor__content" :editor="editor" />
  <EditorMenuBar :editor="editor">
  <div class="menubar editor-toolbar" slot-scope="{ commands }">
    <button class="menubar-button" @click="showVideoModal(commands.iframe)">
      <Icon name="video"/>
    </button>
  </div>
</EditorMenuBar>
  <v-btn
    color="green"
    dark
    small
    relative
    right
    v-on:click="updateDoc"
  >
    Save
  </v-btn>
  <v-snackbar
      v-model="snackbar"
      top
      color="green"
    >
      Successfully saved!
    </v-snackbar>
</div>
</v-container>
</v-col>
 </v-row>
</v-app>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
} from 'tiptap-extensions'
import Iframe from '../plugins/Iframe.js'
import Modal from "../plugins/Modal";
import firebase from 'firebase'

var db = firebase.firestore();

export default {
  components: {
    EditorContent,
    EditorMenuBar,
    Modal
  },
  data() {
    return {
      items: [],
      dialog: false,
      ny: null,
      snackbar: false,
      html: null,
      activeContent: "<h1>test</h1>",
      open: [1],
      editor: new Editor({
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Iframe(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
        ],
      content: "<h1><- Select a doc</h1>",
      }),
    }
  },
  created () {
    db.collection("docs")
      .onSnapshot(querySnapshot => {
        var dc = []
        this.items = dc
          querySnapshot.forEach(doc => {
            var data = {
              'id': doc.id,
              'title': doc.data().title,
              'contentus': doc.data().content,
            }
              dc.push(data);
          });
      });
  },
  watch () {
    console.log(getHTML())
    this.html = getHTML()
  },
  methods: {
    openModal(command) {
      this.$refs.ytmodal.showModal(command);
    },
    addCommand(data) {
      if (data.command !== null) {
        data.command(data.data);
      }
    },
    activate: function(contentus, id) {
      this.activeContent = id
      this.editor.destroy()
      this.editor = new Editor({
        onUpdate: ( { state, getHTML, getJSON, transaction } ) => {
          this.html = getHTML().toString()
        },
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Iframe(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
        ],
      content: contentus,
    })
  },
  addDoc() {
    db.collection("docs").add({
    title: this.docnew,
    content: ""
    })
    .then(docRef => {
        console.log("Document written with ID: ", docRef.id);
        this.dialog = false
    })
    .catch(function(error) {
        console.error("Error adding document: ", error);
    });
  },
  updateDoc() {
    db.collection("docs").doc(this.activeContent).update({
    content: this.html,
    })
    .then(() => {
        console.log("Document successfully updated!");
        this.snackbar = true
    })
    .catch(function(error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    });
  },
  beforeDestroy() {
    this.editor.destroy()
  },
}
}
</script>
