<template>
  <v-container class="justify-center">
    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSekRY_gk0ikWV18j4an1hAE6u3QW4PsrdBAOtF60bRAzUR-_Q/viewform?embedded=true" width="640" height="1838" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    date: null,
  })
}
</script>
