<template>
  <v-container grid-list-md text-center >
    <v-form @submit.prevent="profilePic"
    ref="form">
    <v-layout wrap v-for="(user, id) in users"  :key="id">

      <v-flex xs12 >

      </v-flex>

      <v-flex xs3>

        <v-img
          height="320"
          width="300"
          :src="imgageUrl"
          :lazy-src="lazyPic"></v-img>
          <v-file-input accept="image/*" v-model="test" label="Change profile picture" v-on:change="onFilePicked(test)"></v-file-input>
          <v-btn v-if="image != null || number != oriNum" :loading="loading" type="submit" color="success">Save</v-btn>

      </v-flex>
      <v-flex xs8 pl-5>

        <v-text-field
          :value="user.name.fullName"
          label="Name"
          outlined
          filled
          readonly
        ></v-text-field>
        <v-text-field
          :value="user.primaryEmail"
          label="Email"
          outlined
          filled
          readonly
        ></v-text-field>
          <v-text-field
            v-if="!symp"
            v-model="oriNum"
            label="Number"
            outlined
            v-mask="mask"
          ></v-text-field>
          <v-text-field
            v-else
            v-model="oriNum"
            label="Number"
            outlined
            filled
            readonly
          ></v-text-field>
        <div v-for="(organization, i) in user.organizations"  :key="i">
        <v-text-field
          :value="organization.department"
          label="Department"
          outlined
          filled
          readonly
        ></v-text-field>
        <v-text-field
          :value="organization.title"
          label="Title"
          outlined
          filled
          readonly
        ></v-text-field>
      </div>
      <div v-for="relation in user.relations"  :key="1284945">
        <v-text-field
          :value="relation.value"
          label="My Manager"
          outlined
          filled
          readonly
        ></v-text-field>
       </div>

         <v-textarea
           v-if="user.customSchemas"
           :value="user.customSchemas.Work.Responsability"
           label="Work description"
           outlined
           filled
           readonly
         ></v-textarea>
       <v-btn
         color="blue"
         class="ma-2 white--text"
         href="https://www.sympahr.net/index.aspx?y=idealofsweden"
         target="blank"
       >
         My Sympa profile
         <v-icon right dark>mdi-open-in-new</v-icon>
       </v-btn>

      </v-flex>

    </v-layout>
    </v-form>
  </v-container>
</template>

<script>
import axios from 'axios'
import firebase from 'firebase'
import KJUR from 'jsrsasign'
import { mask } from 'vue-the-mask'

  export default {
    directives: {
      mask
    },
    name: 'Profile',
    data () {
      return {
        users: [],
        tokki: [],
        image: null,
        symp: false,
        imgageUrl: '',
        mask: '+################',
        dep: '',
        title: '',
        oriNum: '',
        number: '',
        test: null,
        loading: false,
        webSafe: [],
        fullName: $cookies.get("aW50cmFuZXRjcmVkZW50aWFscw==").fullname,
        filename: '',
        id: '',
        lazyPic: 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png',
      }
    },
    created () {
      var pHeader = {"alg":"RS256","typ":"JWT"}
      var sHeader = JSON.stringify(pHeader);
      var key = "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDLCCJQk/oBYTn5\nQ1J08vP1vLRWAKBB20hhr+8yhC3/u9VQXG1vc+0PyIr5dIcmLOXJfe537Ou0Dpfy\n+r931HDBVwJaJoYNEWhY9Wv5lrw1RJs8kK8enH1iX31Qkc5+VbpArD1QF8Nn4bsE\n0A2HVFzIOaWjsIvK3Htx8VXW0eTNpXpSS9H3/rhJPg1Y48y3LVNf4ibyrdgmH/qF\nULRJmHtBwmnwoWFNyCkRB7ZXAX6hHPGQ6ItH3KWJQ8bFtQ4wibkrzuQMv+xysXG3\nvQA1ybndxq3RPeO4gNgzvHIZTjYJBYuNC5rkGzfGEiVuNzpcsrYVLMg+ZwCCLEPD\n+hqY9X1hAgMBAAECggEABTRgLNCeSArGAa3IH1dbCaGcvdemRAnrARYRe6oHw9Z8\n0WKNK549JXpulZZ3IHNmMJQZ2Nkp8aOuIXxsDBZWDZ69YLI8HXmkwyMHcY57pKHt\nmM/crVw1mp/4Bmi2cmagL6g6qiLxz2fp82Wh8yYmert/k4KjEsA0KeeV27vlsZ/Q\nkrFZPrC5oSTP5OpguJwOZ/A661h503Z/zgnpqOjhm0sLHPcEkt5revBKNlvBIARM\nejf7e2HRjrOcJV5uC/s2Muop7pmorqWWrVFTmFgLfgX4dJoEvP0s7yr4AjqFnTop\nv99g8B8Ysbd1bptMMPjFKx0bX+nV18LdXkxnr2dMAQKBgQD8xY6ExyRgbiFADiki\nc71MirrFLnoUITGjthldykHnALl0te5JwcWRi2TTJHI8rZh6o3j1vg/ciaiwe3kL\n7n8/GEmPGwmlsSPh2wPU9kq5qiUbsHupwnrwVleOYfqMRCas1jEJVMw1+KpvqyZX\nWkG0AT8j0Nr6nDbNd4STrqvKOQKBgQDNn/OPA3Ks54pbqD/mwrWpk4h4Yik5qqwD\nOQL2+0s5aI4TtEeU0kI74u2u/AMlVqch0e/vPDE2oiu4Ri9MQKFOed2Vb/IeexyZ\nK/X7c1xIa3wDG4Fpp7Zf44ENlz72m3T4AQs0/QSFVepHH5o2eSbt+zNbvTXQBFPE\nI/XVkqvsaQKBgQD0AUEyq4pSC/3M9959g3yZBtKExfjStbhVcNPL1hMIC4r7PyRn\nzsYJWaZarDQanvkyadu6XV6yZbCRrJ+NBQ2QusFHg7IsYHNi8ykfyyEXWUe5EwRN\nO4+xsaeTqsFR5ffvoFR1zYXUE9dzNKQubtI+EXYB/PtmMKY2cclpRpRPAQKBgQCC\nRUPY5yZPxKEzUHSrjc/pjz52hjn3/tjbcdMfBjWyOTtJ4H05G7w6lJpgseiMT0mz\nc32+4zOKmVN5e32boZaQUUTA2BtclEKaB+/fTka42t/GWDa06TvxpACuy5NoSTTh\nqkefWmzyzGuQg8T/neG8A9mV1VmyYAtxyBaHKFUjgQKBgHsMG7xOZGTFR6ob68JS\nPmjeOR3kKC8vIRd7usWi1w9ZvP3cY1s5hgyLyUjc+KN+eeFLP20nugHzPklDF2/v\nlwrYh8BdJzEAIOXBNI5PTZ/fLey6eM1Ep5cd7oflOMLNHSyo5tawr0c14MLYvsw/\n3g4txWFg8v4ibK/NafsFrdSf\n-----END PRIVATE KEY-----\n";`4616`
      var pClaim = {
        "aud": "https://oauth2.googleapis.com/token",
        "scope": "https://www.googleapis.com/auth/admin.directory.user",
        "iss": "idos-425@intranet-261309.iam.gserviceaccount.com",
        "exp": Math.floor(Date.now()/1000)+3600,
        "iat": Math.floor(Date.now()/1000),
        "sub": "onelogin@idealofsweden.com"
        };
      var sClaim = JSON.stringify(pClaim);

      var sJWS = KJUR.jws.JWS.sign(null, sHeader, sClaim, key);
      axios({
        url: 'https://oauth2.googleapis.com/token',
        method: 'post',
        data: {
                 'grant_type': 'urn:ietf:params:oauth:grant-type:jwt-bearer',
                 'assertion': sJWS
              }
          }).then(response => {
            this.tokki.push(response.data)
            axios({
              url: 'https://www.googleapis.com/admin/directory/v1/users/',
              method: 'get',
              headers: {
                   'Accept': 'application/x-www-form-urlencoded',
                   'Authorization': 'Bearer ' + response.data.access_token,
              },
              params: {
                       'viewType': 'admin_view',
                       'projection': 'full',
                       'domain': 'idealofsweden.com',
                       'query': 'email=' + $cookies.get("aW50cmFuZXRjcmVkZW50aWFscw==").email + '*',
                       'key': '60a0a2c29f86e61a0f9b3d781ddf34c4be166d2d'
                      }
                }).then(response => {
                  if (typeof response.data.users != 'undefined') {
                    this.users = response.data.users
                    if (typeof response.data.users[0].phones[0].value != 'undefined') {
                      this.oriNum = response.data.users[0].phones[0].value.toString()
                      this.number = response.data.users[0].phones[0].value.toString()
                      }
                      response.data.users.forEach(user => {
                      this.id = user.id
                      user.organizations.forEach(org => {
                      this.dep = org.department
                      this.title = org.title
                      this.imgageUrl = org.costCenter
                    })
                  })
                } else {
                  axios({
                    url: 'https://europe-west2-idos-4bb5f.cloudfunctions.net/intranet_internsimport',
                    method: 'get',
                    headers: {
                         'Accept': 'application/json',
                         'Authorization': 'Basic YjA0ZDg4YTQ1Zjc2MmJmNGY5NTZlOGRjOGZhYWRmM2Y6MTdiM2E1MDNjMjE3ZWNkYjAxMDcyZTM0MThjMjhkMTI=',
                    }
                      }).then(response => {
                        var list = response.data;
                        var now = Date.now();
                        var result = list.filter(emp => emp.DJ_Private_mail == $cookies.get("aW50cmFuZXRjcmVkZW50aWFscw==").email);
                        console.log(result)
                        var tempArr = [];
                        if (result.length > 0) {
                          let dat = {
                            "gotResp": false,
                            "id": result[0].AA_Employee_number,
                            "emails": [{
                              "address": result[0].DJ_Private_mail,
                              "primary": true
                            }],
                            "locations": [{
                              "area": "desk",
                              "buildingId": result[0].DD_Location,
                              "floorName": "",
                              "type": "desk"
                            }],
                            "name": {
                              "familyName": result[0].AB_Surname,
                              "fullName": result[0].AC_First_names + " " + result[0].AB_Surname,
                              "givenName": result[0].AC_First_names
                            },
                            "organizations": [{
                              "costCenter": "",
                              "customType": "",
                              "description": "Intern",
                              "department": "Influencer Marketing",
                              "primary": true,
                              "title": result[0].Employment_journey[result[0].Employment_journey.length - 1].Role
                            }],
                            "phones": [{
                              "type": "work",
                              "value": result[0].DF_Phone_number
                            }],
                            "primaryEmail": result[0].DJ_Private_mail,
                            "relations": [{
                              "type": "manager",
                              "value": result[0].AF_Manager_Email
                            }]
                          }
                          this.users = [dat]
                          if (typeof result[0].Profile_picture[0] != "undefined") {
                          dat.organizations[0].costCenter = result[0].Profile_picture[result[0].Profile_picture.length - 1].Profile_picture_column
                          }
                          this.oriNum = dat.phones[0].value.toString()
                          this.number = dat.phones[0].value.toString()
                          this.id = dat.id
                          this.dep = "Influencer Marketing"
                          this.title = dat.organizations[0].title
                          this.imgageUrl = dat.organizations[0].costCenter
                          this.symp = true
                        }
                      })
                }

            }).catch(function (error) {
          console.log(error);
        });
        }).catch(function (error) {
            console.log(error);
          })
    },
    methods: {
      profilePic () {
        if (!this.symp) {
          var pHeader = {"alg":"RS256","typ":"JWT"}
          var sHeader = JSON.stringify(pHeader);
          var key = "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDLCCJQk/oBYTn5\nQ1J08vP1vLRWAKBB20hhr+8yhC3/u9VQXG1vc+0PyIr5dIcmLOXJfe537Ou0Dpfy\n+r931HDBVwJaJoYNEWhY9Wv5lrw1RJs8kK8enH1iX31Qkc5+VbpArD1QF8Nn4bsE\n0A2HVFzIOaWjsIvK3Htx8VXW0eTNpXpSS9H3/rhJPg1Y48y3LVNf4ibyrdgmH/qF\nULRJmHtBwmnwoWFNyCkRB7ZXAX6hHPGQ6ItH3KWJQ8bFtQ4wibkrzuQMv+xysXG3\nvQA1ybndxq3RPeO4gNgzvHIZTjYJBYuNC5rkGzfGEiVuNzpcsrYVLMg+ZwCCLEPD\n+hqY9X1hAgMBAAECggEABTRgLNCeSArGAa3IH1dbCaGcvdemRAnrARYRe6oHw9Z8\n0WKNK549JXpulZZ3IHNmMJQZ2Nkp8aOuIXxsDBZWDZ69YLI8HXmkwyMHcY57pKHt\nmM/crVw1mp/4Bmi2cmagL6g6qiLxz2fp82Wh8yYmert/k4KjEsA0KeeV27vlsZ/Q\nkrFZPrC5oSTP5OpguJwOZ/A661h503Z/zgnpqOjhm0sLHPcEkt5revBKNlvBIARM\nejf7e2HRjrOcJV5uC/s2Muop7pmorqWWrVFTmFgLfgX4dJoEvP0s7yr4AjqFnTop\nv99g8B8Ysbd1bptMMPjFKx0bX+nV18LdXkxnr2dMAQKBgQD8xY6ExyRgbiFADiki\nc71MirrFLnoUITGjthldykHnALl0te5JwcWRi2TTJHI8rZh6o3j1vg/ciaiwe3kL\n7n8/GEmPGwmlsSPh2wPU9kq5qiUbsHupwnrwVleOYfqMRCas1jEJVMw1+KpvqyZX\nWkG0AT8j0Nr6nDbNd4STrqvKOQKBgQDNn/OPA3Ks54pbqD/mwrWpk4h4Yik5qqwD\nOQL2+0s5aI4TtEeU0kI74u2u/AMlVqch0e/vPDE2oiu4Ri9MQKFOed2Vb/IeexyZ\nK/X7c1xIa3wDG4Fpp7Zf44ENlz72m3T4AQs0/QSFVepHH5o2eSbt+zNbvTXQBFPE\nI/XVkqvsaQKBgQD0AUEyq4pSC/3M9959g3yZBtKExfjStbhVcNPL1hMIC4r7PyRn\nzsYJWaZarDQanvkyadu6XV6yZbCRrJ+NBQ2QusFHg7IsYHNi8ykfyyEXWUe5EwRN\nO4+xsaeTqsFR5ffvoFR1zYXUE9dzNKQubtI+EXYB/PtmMKY2cclpRpRPAQKBgQCC\nRUPY5yZPxKEzUHSrjc/pjz52hjn3/tjbcdMfBjWyOTtJ4H05G7w6lJpgseiMT0mz\nc32+4zOKmVN5e32boZaQUUTA2BtclEKaB+/fTka42t/GWDa06TvxpACuy5NoSTTh\nqkefWmzyzGuQg8T/neG8A9mV1VmyYAtxyBaHKFUjgQKBgHsMG7xOZGTFR6ob68JS\nPmjeOR3kKC8vIRd7usWi1w9ZvP3cY1s5hgyLyUjc+KN+eeFLP20nugHzPklDF2/v\nlwrYh8BdJzEAIOXBNI5PTZ/fLey6eM1Ep5cd7oflOMLNHSyo5tawr0c14MLYvsw/\n3g4txWFg8v4ibK/NafsFrdSf\n-----END PRIVATE KEY-----\n";`4616`
          var pClaim = {
            "aud": "https://oauth2.googleapis.com/token",
            "scope": "https://www.googleapis.com/auth/admin.directory.user",
            "iss": "idos-425@intranet-261309.iam.gserviceaccount.com",
            "exp": Math.floor(Date.now()/1000)+3600,
            "iat": Math.floor(Date.now()/1000),
            "sub": "onelogin@idealofsweden.com"
            };
          var sClaim = JSON.stringify(pClaim);

          var sJWS = KJUR.jws.JWS.sign(null, sHeader, sClaim, key);
          axios({
            url: 'https://oauth2.googleapis.com/token',
            method: 'post',
            data: {
                     'grant_type': 'urn:ietf:params:oauth:grant-type:jwt-bearer',
                     'assertion': sJWS
                  }
              }).then(response => {
                var token = response.data.access_token
                if (this.image) {
                  this.loading = true
                  var file = this.image // use the Blob or File API
                  var ids = this.id
                  var num = this.oriNum
                  var deps = this.dep
                  var filename = this.filename
                  var pici = this.webSafe[0]
                  var tits = this.title
                  var tok = this.tokki[0].access_token
                  var storageRef = firebase.storage().ref('profilePics/' + filename)
                  var metadata = {
                    contentType: this.image.type,
                  }
                  storageRef.put(file, metadata).then(function(snapshot) {
                    firebase.storage().ref('profilePics/' + filename).getDownloadURL().then(url => {
                      axios({
                        url: 'https://us-central1-idos-4bb5f.cloudfunctions.net/intranet_updateProfile?img=' + url + "&phn=" + num + "&gt=" + token + "&ids=" + ids + "&tits=" + tits + "&deps=" + deps,
                        method: 'post',
                        headers: {
                             'Access-Control-Allow-Origin': '*',
                             'Accept': '*/*'
                        }
                          }).then(response => {
                            console.log("Responns: ",response)
                            window.location.reload()
                          }).catch(function (error) {
                    console.log(error);
                  });
                    })
                  });
                } else {
                  this.loading = true
                  var ids = this.id
                  var num = this.oriNum
                  var tok = this.tokki[0].access_token
                  axios({
                    url: 'https://us-central1-idos-4bb5f.cloudfunctions.net/intranet_updateProfilePhone?phn=' + num + "&gt=" + token + "&ids=" + ids,
                    method: 'post',
                    headers: {
                         'Access-Control-Allow-Origin': '*',
                         'Accept': '*/*'
                    }
                      }).then(response => {
                        console.log("Responns: ",response)
                        window.location.reload()
                      }).catch(function (error) {
                console.log(error);
              });
                }
            }).catch(function (error) {
                console.log(error);
              })
        } else {




          this.loading = true
          var file = this.image // use the Blob or File API
          var ids = this.id
          var num = this.oriNum
          var deps = this.dep
          var filename = this.filename
          var pici = this.webSafe[0]
          var tits = this.title
          var tok = this.tokki[0].access_token
          var storageRef = firebase.storage().ref('profilePics/' + filename)
          var metadata = {
            contentType: this.image.type,
          }
          storageRef.put(file, metadata).then(function(snapshot) {
            firebase.storage().ref('profilePics/' + filename).getDownloadURL().then(url => {
              axios({
                url: 'https://europe-west2-idos-4bb5f.cloudfunctions.net/intranet_updatesympapicture?url=' + url + '&id=' + ids,
                method: 'post',
                headers: {
                     'Access-Control-Allow-Origin': '*',
                     'Accept': '*/*'
                }
                  }).then(response => {
                    console.log("Responns: ",response)
                    window.location.reload()
                  }).catch(function (error) {
            console.log(error);
          });
            })
          });
        }


      },
      onPickFile () {
        this.$refs.fileinput.click()
      },
      onFilePicked (fil) {
        const files = event.target.files
        let filename = files[0].name;
        if (filename.lastIndexOf('.') <= 0) {
          return alert('Please pick a valid file')
        }
        const fileReader = new FileReader()
        fileReader.addEventListener('load', () => {
          var cleanStart = fileReader.result.indexOf("/9j/")
          var clean = fileReader.result.slice(cleanStart).replace(/\//g,"_")
          var clean2 = clean.replace(/\+/g,"-")
          var cleanest = clean2.replace(/=/g,"*")
          this.webSafe.push(cleanest)
          this.imgageUrl = fileReader.result
        })
        fileReader.readAsDataURL(files[0])
        var pos = files[0].name.lastIndexOf(".");
        var ext = files[0].name.slice(pos);
        var res = this.fullName.concat(ext);

        this.filename = res
        this.image = files[0]
      }
    }
  }
</script>
<style>
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
