<template>
  <div>
    <v-container
      class="grey lighten-5 mt-5 mb-5"
    >
    <v-layout row wrap>
      <v-flex xs4>
          <v-form
          @submit.prevent="addApp"
          ref="form">
          <v-text-field
            label="Category"
            v-model="newAppCat"
          ></v-text-field>
            <v-text-field
              label="Text"
              v-model="newAppText"
            ></v-text-field>
            <v-text-field
              label="Mention (email)"
              v-model="newAppMen"
            ></v-text-field>
            <v-btn class="mb-5" color="success" Type="submit">Add Appreciation</v-btn>
          </v-form>
        </v-flex>
      <v-flex xs6 offset-xs2>
        <v-list>
          <template v-for="app in apps">

            <v-list-item v-if="app.id != 'snaXacarLzEo3iORuic6'">
              <v-list-item-content>
                <v-list-item-title v-html=""><b>{{ app.category }}</b> - {{ app.text }}</br>{{ app.mention }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon ripple v-on:click="deleteApp(app.id)">
                  <v-icon color="red">delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
    </v-flex>
  </v-layout>
    </v-container>
  </div>
</template>


<script>
import firebase from 'firebase'
import axios from 'axios'

var db = firebase.firestore();
  export default {
    data () {
      return {
        alignments: {},
        newAppText: "",
        newAppCat: "",
        newAppMen: "",
        apps: {},
      }
    },
    created () {
      db.collection("appreciation").orderBy("time", "desc").onSnapshot(querySnapshot => {
          var ad = []
          this.apps = ad
            querySnapshot.forEach(doc => {
              var d = {
                'id': doc.id,
                'text': doc.data().text,
                'category': doc.data().category,
                'mention': doc.data().mention
              }
              this.apps.push(d)
            });
        });
    },
    methods: {
      addApp () {
      db.collection('appreciation').add({
        text: this.newAppText,
        category: this.newAppCat,
        mention: this.newAppMen,
        time: new Date()
      }).then(() => {
        if (this.newAppMen != "") {
          axios({
            url: 'https://hooks.zapier.com/hooks/catch/5015474/or8e25r?email=' + this.newAppMen,
            method: 'post'
          }).then(response =>  {
            this.$refs.form.reset()
          })
        } else {
          this.$refs.form.reset()
        }
      })
    },
      deleteApp: function(id) {
        if(confirm ('Are you sure?')) {
          db.collection("appreciation").doc(id).delete().then(function() {
          }).catch(function(error) {
              console.error("Error removing document: ", error);
          });
        }
      }
    }
  }
</script>
