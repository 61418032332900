<template>
  <div>
    <v-tabs v-model="tabsi" fixed-tabs dark>
  <v-tab>
    Tabs
  </v-tab>
<v-tab>
  Flowchart
</v-tab>
</v-tabs>
<v-tabs-items v-model="tabsi">
<v-tab-item>
      <v-card style="height:100%">
        <v-card-title class="text-center justify-center py-6">
          <v-autocomplete :items="allUsers" color="white" hide-no-data v-model="model" :search-input.sync="search" hide-selected item-text="name.fullName" item-value="id" label="Colleagues" placeholder="Start typing to Search" prepend-icon="mdi-database-search" return-object rounded solo>
          </v-autocomplete>
        </v-card-title>
        <v-tabs v-model="tab" background-color="transparent" show-arrows>
          <v-tab v-for="item in items" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" style="padding-top: 50px;">



        <div >
          <v-tab-item v-for="(dep, index) in users"  :key="index">
            <v-container fluid>
            <v-layout wrap>
            <v-flex xs12 md3 xl2 pb-5 v-for="(user, i) in dep"  :key="i">
              <v-card class="mx-auto" max-width="300" min-height="550" max-height="552">
              <v-img v-for="organization in user.organizations"  :key="3" height="320" lazy-src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png" v-bind:src="organization.costCenter">
              <div class="float-left" style="margin-top: 95%; margin-left:5%" v-for="(org, pa) in user.organizations" :key="pa">
                <v-chip style="height: 20px;" color="grey" text-color="white" v-if="org.description === 'Intern'">
                  Intern
                </v-chip>
                <v-chip style="height: 20px;" color="grey" text-color="white" v-if="org.description === 'Consultant'">
                  Consultant
                </v-chip>
              </div>
              <div class="float-right" style="margin-top: 95%; margin-right:5%" v-for="(location,pi) in user.locations" :key="pi">
                <v-chip style="height: 20px;" color="orange" text-color="white" v-if="location.buildingId === 'Norrköping'">
                  Norrköping
                </v-chip>
                <v-chip style="height: 20px;" color="blue" text-color="white" v-else-if="location.buildingId === 'Stockholm'">
                  Stockholm
                </v-chip>
                <v-chip style="height: 20px;" color="grey" text-color="white" v-else-if="location.buildingId === 'loa'">
                Leave Of Abcense
                </v-chip>
                <v-chip style="height: 20px;" color="purple" text-color="white" v-else>
                  Global
                </v-chip>
              </div>
              </v-img>
              <v-card-title class="align-end title fill-height"> {{ user.name.fullName }}</br></v-card-title>
                <v-card-text v-for="organization in user.organizations"  :key="0">
                  <span class="font-weight-bold">{{ organization.department }}</span> </br> <span class="font-italic font-weight-bold">{{ organization.title }}</span>
                </br>
                <span v-if="user.gotResp">
                  {{ user.customSchemas.Work.Responsability }}
                </span>
                  <span v-else class="text--primary">
                    <span>{{ user.primaryEmail }}</span><br>
                    <div v-for="phone in user.phones"  :key="1">
                    <span v-if="">{{ phone.value }}</span>
                  </div>
                  </span>
                </v-card-text>
                <div style="position: absolute; bottom: 0; padding: 0 10px 10px">
                  <v-btn class="white--text" color="#041A36" style="margin-right: 5px" target="blank" :href="'mailto:' + user.primaryEmail">
                    Email
                  </v-btn>
                  <v-btn class="white--text" color="#041A36" v-bind:to="{ name: 'Employee', params: { userKey: user.id }}">
                    View
                  </v-btn>
                </div>
              </v-card>
           </v-flex>
         </v-layout>
       </v-container>
      </v-tab-item>
    </div>
        </v-tabs-items>
      </v-card>
    </v-tab-item>
    <v-tab-item>
      <div style="width: 100%; height: 700px; margin: 10px;"><iframe allowfullscreen frameborder="0" style="width:100%; height:1000px" src="https://app.lucidchart.com/documents/embeddedchart/3508f5dc-f5e2-4188-985d-54787ae13b41" id=".LiAlsctGfBJ"></iframe></div>
    </v-tab-item>
  </v-tabs-items>
</div>
</template>
<script src="https://www.gstatic.com/firebasejs/6.2.2/firebase-auth.js"></script>
<script>
import firebase from 'firebase'
import axios from 'axios'
import KJUR from 'jsrsasign'

  export default {
    name: 'col',
    data () {
      return {
        tab: null,
        tabsi: null,
        model: null,
        search: null,
        users: {
          influ: [],
          marketing: [],
          design: [],
          ecom: [],
          it: [],
          paidSocial: [],
          cs: [],
          csr: [],
          pmo: [],
          supply: [],
          wholesale: [],
          hr: [],
          finance: [],
          productDev: [],
          sManagement: [],
          office: []
        },
        allUsers: [],
        items: [
          'Influencer Marketing',
          'Marketing',
          'Design',
          'E-Commerce',
          'IT',
          'Performance Marketing',
          'Customer Service',
          'Sustainability',
          'PMO',
          'Supply Chain',
          'Wholesale',
          'HR',
          'Finance',
          'Product Development',
          'Senior Management',
          'Office'
        ],
      }
    },

    watch: {
      search () {
        if (this.model != null) {
          this.$router.push({ name: 'Employee', params: { userKey: this.model.id }})
        }
      },
    },
    created () {

      if (this.$route.query.dp) {
        this.tab = parseInt(this.$route.query.dp)
      }
    },
   mounted () {
          var pHeader = {"alg":"RS256","typ":"JWT"}
          var sHeader = JSON.stringify(pHeader);
          var key = "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDLCCJQk/oBYTn5\nQ1J08vP1vLRWAKBB20hhr+8yhC3/u9VQXG1vc+0PyIr5dIcmLOXJfe537Ou0Dpfy\n+r931HDBVwJaJoYNEWhY9Wv5lrw1RJs8kK8enH1iX31Qkc5+VbpArD1QF8Nn4bsE\n0A2HVFzIOaWjsIvK3Htx8VXW0eTNpXpSS9H3/rhJPg1Y48y3LVNf4ibyrdgmH/qF\nULRJmHtBwmnwoWFNyCkRB7ZXAX6hHPGQ6ItH3KWJQ8bFtQ4wibkrzuQMv+xysXG3\nvQA1ybndxq3RPeO4gNgzvHIZTjYJBYuNC5rkGzfGEiVuNzpcsrYVLMg+ZwCCLEPD\n+hqY9X1hAgMBAAECggEABTRgLNCeSArGAa3IH1dbCaGcvdemRAnrARYRe6oHw9Z8\n0WKNK549JXpulZZ3IHNmMJQZ2Nkp8aOuIXxsDBZWDZ69YLI8HXmkwyMHcY57pKHt\nmM/crVw1mp/4Bmi2cmagL6g6qiLxz2fp82Wh8yYmert/k4KjEsA0KeeV27vlsZ/Q\nkrFZPrC5oSTP5OpguJwOZ/A661h503Z/zgnpqOjhm0sLHPcEkt5revBKNlvBIARM\nejf7e2HRjrOcJV5uC/s2Muop7pmorqWWrVFTmFgLfgX4dJoEvP0s7yr4AjqFnTop\nv99g8B8Ysbd1bptMMPjFKx0bX+nV18LdXkxnr2dMAQKBgQD8xY6ExyRgbiFADiki\nc71MirrFLnoUITGjthldykHnALl0te5JwcWRi2TTJHI8rZh6o3j1vg/ciaiwe3kL\n7n8/GEmPGwmlsSPh2wPU9kq5qiUbsHupwnrwVleOYfqMRCas1jEJVMw1+KpvqyZX\nWkG0AT8j0Nr6nDbNd4STrqvKOQKBgQDNn/OPA3Ks54pbqD/mwrWpk4h4Yik5qqwD\nOQL2+0s5aI4TtEeU0kI74u2u/AMlVqch0e/vPDE2oiu4Ri9MQKFOed2Vb/IeexyZ\nK/X7c1xIa3wDG4Fpp7Zf44ENlz72m3T4AQs0/QSFVepHH5o2eSbt+zNbvTXQBFPE\nI/XVkqvsaQKBgQD0AUEyq4pSC/3M9959g3yZBtKExfjStbhVcNPL1hMIC4r7PyRn\nzsYJWaZarDQanvkyadu6XV6yZbCRrJ+NBQ2QusFHg7IsYHNi8ykfyyEXWUe5EwRN\nO4+xsaeTqsFR5ffvoFR1zYXUE9dzNKQubtI+EXYB/PtmMKY2cclpRpRPAQKBgQCC\nRUPY5yZPxKEzUHSrjc/pjz52hjn3/tjbcdMfBjWyOTtJ4H05G7w6lJpgseiMT0mz\nc32+4zOKmVN5e32boZaQUUTA2BtclEKaB+/fTka42t/GWDa06TvxpACuy5NoSTTh\nqkefWmzyzGuQg8T/neG8A9mV1VmyYAtxyBaHKFUjgQKBgHsMG7xOZGTFR6ob68JS\nPmjeOR3kKC8vIRd7usWi1w9ZvP3cY1s5hgyLyUjc+KN+eeFLP20nugHzPklDF2/v\nlwrYh8BdJzEAIOXBNI5PTZ/fLey6eM1Ep5cd7oflOMLNHSyo5tawr0c14MLYvsw/\n3g4txWFg8v4ibK/NafsFrdSf\n-----END PRIVATE KEY-----\n";`4616`
          var pClaim = {
            "aud": "https://oauth2.googleapis.com/token",
            "scope": "https://www.googleapis.com/auth/admin.directory.user",
            "iss": "idos-425@intranet-261309.iam.gserviceaccount.com",
            "exp": Math.floor(Date.now()/1000)+3600,
            "iat": Math.floor(Date.now()/1000),
            "sub": "daniel.eriksson@idealofsweden.com"
            };
          var sClaim = JSON.stringify(pClaim);

          var sJWS = KJUR.jws.JWS.sign(null, sHeader, sClaim, key);
          axios({
            url: 'https://oauth2.googleapis.com/token',
            method: 'post',
            data: {
                     'grant_type': 'urn:ietf:params:oauth:grant-type:jwt-bearer',
                     'assertion': sJWS
                  }
              }).then(response => {
                //this.tokki.push(response.data)
                  var token = response.data.access_token //$cookies.get("aW50cmFuZXRjcmVkZW50aWFscw==").token
                  axios({
                    url: 'https://www.googleapis.com/admin/directory/v1/users',
                    method: 'get',
                    headers: {
                         'Accept': 'application/json',
                         'Authorization': 'Bearer ' + token,
                    },
                    params: {
                             'viewType': 'domain_public',
                             'domain': 'idealofsweden.com',
                             'projection': 'full',
                             'maxResults': 500,
                             'key': '60a0a2c29f86e61a0f9b3d781ddf34c4be166d2d'
                            }
                  }).then(response => {
                    console.log(response)
                    for (var i = 0; i < response.data.users.length; i++) {
                      if (typeof response.data.users[i].organizations == 'undefined') {
                        continue
                      } else {
                        this.allUsers.push(response.data.users[i])
                        var user = response.data.users[i]
                        var dep = user.organizations[0].department
                        user.gotResp = false
                        if (user.hasOwnProperty('customSchemas')) {
                          if (user.customSchemas.Work.hasOwnProperty('Responsability')) {
                            user.gotResp = true
                          }
                        }
                        if (dep == 'Influencer Marketing') {
                          this.users.influ.push(user)
                        }
                        if (dep == 'Marketing' || dep == 'Content' || dep == 'Event') {
                          this.users.marketing.push(user)
                        }
                        if (dep == 'Design') {
                          this.users.design.push(user)
                        }
                        if (dep == 'Customer Service' || user.name.fullName == "Louise Kvist") {
                          this.users.cs.push(user)
                        }
                        if (dep == 'CSR') {
                          this.users.csr.push(user)
                        }
                        if (dep == 'PMO') {
                          this.users.pmo.push(user)
                        }
                        if (dep == 'IT') {
                          this.users.it.push(user)
                        }
                        if (dep == 'E-Commerce' || dep == 'Online Marketplaces') {
                          this.users.ecom.push(user)
                        }
                        if (dep == 'Paid Social' || dep == 'Adwords' || dep == 'CRM') {
                          this.users.paidSocial.push(user)
                        }
                        if (dep == 'Supply Chain') {
                          this.users.supply.push(user)
                        }
                        if (dep == 'Sales') {
                          this.users.wholesale.push(user)
                        }
                        if (dep == 'HR') {
                          this.users.hr.push(user)
                        }
                        if (dep == 'Finance') {
                          this.users.finance.push(user)
                        }
                        if (dep == 'Product Development') {
                          this.users.productDev.push(user)
                        }
                        if (user.organizations[0].description == 'Senior Manager') {
                          this.users.sManagement.push(user)
                        }
                        if (dep == 'Office') {
                          this.users.office.push(user)
                        }
                      }
                    }
                    this.users.influ.sort((a, b) => (b.locations[0].buildingId < a.locations[0].buildingId) ? 1 : -1)
                    this.users.marketing.sort((a, b) => (b.locations[0].buildingId < a.locations[0].buildingId) ? 1 : -1)
                    this.users.design.sort((a, b) => (b.locations[0].buildingId < a.locations[0].buildingId) ? 1 : -1)
                    this.users.cs.sort((a, b) => (b.locations[0].buildingId < a.locations[0].buildingId) ? 1 : -1)
                    this.users.csr.sort((a, b) => (b.locations[0].buildingId < a.locations[0].buildingId) ? 1 : -1)
                    this.users.pmo.sort((a, b) => (b.locations[0].buildingId < a.locations[0].buildingId) ? 1 : -1)
                    this.users.it.sort((a, b) => (b.locations[0].buildingId < a.locations[0].buildingId) ? 1 : -1)
                    this.users.ecom.sort((a, b) => (b.locations[0].buildingId < a.locations[0].buildingId) ? 1 : -1)
                    this.users.supply.sort((a, b) => (b.locations[0].buildingId < a.locations[0].buildingId) ? 1 : -1)
                    this.users.wholesale.sort((a, b) => (b.locations[0].buildingId < a.locations[0].buildingId) ? 1 : -1)
                    this.users.hr.sort((a, b) => (b.locations[0].buildingId < a.locations[0].buildingId) ? 1 : -1)
                    this.users.finance.sort((a, b) => (b.locations[0].buildingId < a.locations[0].buildingId) ? 1 : -1)
                    this.users.productDev.sort((a, b) => (b.locations[0].buildingId < a.locations[0].buildingId) ? 1 : -1)
                    this.users.sManagement.sort((a, b) => (b.locations[0].buildingId < a.locations[0].buildingId) ? 1 : -1)
                    this.users.office.sort((a, b) => (b.locations[0].buildingId < a.locations[0].buildingId) ? 1 : -1)
                  })
                  .catch(function (error) {
                    console.log(error)
                  });
                }).catch(function (error) {
                console.log(error);
      })
      axios({
        url: 'https://europe-west2-idos-4bb5f.cloudfunctions.net/intranet_internsimport',
        method: 'get',
        headers: {
             'Accept': 'application/json',
             'Authorization': 'Basic YjA0ZDg4YTQ1Zjc2MmJmNGY5NTZlOGRjOGZhYWRmM2Y6MTdiM2E1MDNjMjE3ZWNkYjAxMDcyZTM0MThjMjhkMTI=',
        }
          }).then(response => {
            var list = response.data;
            console.log(list);
            var now = Date.now();
            var result = list.filter(emp => emp.DC_Team == "Influencer Marketing" && emp.Employment_journey[emp.Employment_journey.length - 1].Employee_group == "Intern" && new Date(emp.Employment_journey[emp.Employment_journey.length - 1].New_role_valid_from_).getTime() < now &&  new Date(emp.Employment_journey[emp.Employment_journey.length - 1].Last_day_of_employment).getTime() > now);
            var tempArr = [];
            for (var i = 0; i < result.length; i++) {
              let dat = {
                "gotResp": false,
                "id": result[i].AA_Employee_number,
                "emails": [{
                  "address": result[i].DJ_Private_mail,
                  "primary": true
                }],
                "locations": [{
                  "area": "desk",
                  "buildingId": result[i].DD_Location,
                  "floorName": "",
                  "type": "desk"
                }],
                "name": {
                  "familyName": result[i].AB_Surname,
                  "fullName": result[i].AC_First_names + " " + result[i].AB_Surname,
                  "givenName": result[i].AC_First_names
                },
                "organizations": [{
                  "costCenter": "",
                  "customType": "",
                  "description": "Intern",
                  "department": "Influencer Marketing",
                  "primary": true,
                  "title": result[i].Employment_journey[result[i].Employment_journey.length - 1].Role
                }],
                "phones": [{
                  "type": "work",
                  "value": result[i].DF_Phone_number
                }],
                "primaryEmail": result[i].DJ_Private_mail,
                "relations": [{
                  "type": "manager",
                  "value": result[i].AF_Manager_Email
                }]
              }
              if (typeof result[i].Profile_picture[0] != "undefined") {
              dat.organizations[0].costCenter = result[i].Profile_picture[result[i].Profile_picture.length - 1].Profile_picture_column
              }
              this.users.influ.push(dat)
              this.allUsers.push(dat)
            }
            })




    }
  }
</script>
