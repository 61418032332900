<template>
  <v-app id="inspire">
    <v-snackbar
        v-model="snackbar"
        top
        color="green"
      >
        Successfully saved!
      </v-snackbar>
    <v-layout wrap>
      <v-flex md2>
        <v-list>
          <v-subheader>Documentation sections</v-subheader>
          <v-list-item-group color="primary">
            <v-list-item
              v-for="(item, i) in items"
              :key="i"
              v-on:click="activate(item.contentus, item.id)"
            >
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action style="display:inline">
                <v-btn icon @click="editDialog = true; editTit = item.title">
                  <v-icon color="orange">mdi-pencil</v-icon>
                </v-btn>

              <v-btn icon @click="delDoc(item.id)">
                <v-icon color="red">mdi-delete</v-icon>
              </v-btn>
            </v-list-item-action>
            </v-list-item>
            <v-list-item>
            <div class="text-center">

              <v-dialog
                v-model="dialog"
                width="500"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="green"
                    dark
                    v-on="on"
                  >
                    Add doc
                  </v-btn>
                </template>

                <v-card>
                  <v-form
                  @submit.prevent="addDoc"
                  >
                  <v-card-title
                    class="headline grey lighten-2"
                    primary-title
                  >
                    Add Document
                  </v-card-title>
                  <v-container>

                  <v-text-field
                    solo
                    v-model="docnew"
                  ></v-text-field>
                </v-container>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="green"
                      text
                      type="submit"
                      @click="dialog = false"
                    >
                      Add
                    </v-btn>

                  </v-card-actions>
                </v-form>
                </v-card>
              </v-dialog>

            </div>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-flex>
      <v-dialog
        v-model="editDialog"
        width="500"
      >
        <v-card>
          <v-form
          @submit.prevent="editDoc"
          >
          <v-card-title
            class="headline grey lighten-2"
            primary-title
          >
            Edit Document
          </v-card-title>
          <v-container>

          <v-text-field
            solo
            :placeholder="editTit"
            v-model="editTit"
          ></v-text-field>
        </v-container>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green"
              text
              type="submit"
              @click="editDialog = false"
            >
              Save
            </v-btn>

          </v-card-actions>
        </v-form>
        </v-card>
      </v-dialog>
      <v-flex md10>
    <!--<admdoc></admdoc>-->
        <quill-editor ref="myTextEditor"
                  v-model="content"
                  :options="editorOption"
                  @blur="onEditorBlur($event)"
                  @change="onEditorChange($event)"
                  @ready="onEditorReady($event)">

        </quill-editor>
        <v-btn
        v-if="!this.newPDF"
        color="blue"
        dark
        bottom
        right
      >
      Upload PDF
      <v-file-input accept="application/pdf" :v-model="this.pdf" v-on:change="onFilePicked(pdf)"></v-file-input>
      </v-btn>

        <v-btn
        v-on:click="updateDoc()"
        v-if="this.savebtn"
        color="#041A36"
        dark
        fixed
        bottom
        right
      >
      Save
      </v-btn>
      </v-flex>
    </v-layout>
  </v-app>
</template>

<script>
import admdoc from '../components/admdoc.vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import hljs from 'highlight.js'
import { quillEditor } from 'vue-quill-editor'
import firebase from 'firebase'

var db = firebase.firestore();

export default {
  name: 'adm',
  components: {
    admdoc,
    quillEditor
  },
  data () {
    return {
      name: '01-example',
      activeContent: '',
      oriContent:'',
      items: [],
      pdf: null,
      savebtn: false,
      newPDF: false,
      snackbar: false,
      dialog: false,
      editDialog: false,
      editTit: "",
      docnew: "",
      content: `<h2 class="ql-align-center"><span>Documentation loading..</span></h2>`,
      editorOption: {
        modules: {
          clipboard: {
            matchVisual: false
          },
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'font': [] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['clean'],
            ['link', 'image']
          ],

          syntax: {
            highlight: text => hljs.highlightAuto(text).value
          }
        }
      }
    }
  },
  created () {
    db.collection("docs")
      .onSnapshot(querySnapshot => {
        var dc = []
        this.items = dc
          querySnapshot.forEach(doc => {
            var data = {
              'id': doc.id,
              'title': doc.data().title,
              'contentus': doc.data().content,
            }
              dc.push(data);
          });
      });
  },
  methods: {
    async onFilePicked (fil) {
      const files = event.target.files
      let filename = files[0].name;
      if (filename.lastIndexOf('.') <= 0) {
        return alert('Please pick a valid file')
      }
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        var cleanStart = fileReader.result.indexOf("/9j/")
        var clean = fileReader.result.slice(cleanStart).replace(/\//g,"_")
        var clean2 = clean.replace(/\+/g,"-")
        var cleanest = clean2.replace(/=/g,"*")
        //this.webSafe.push(cleanest)
        //this.imgageUrl = fileReader.result
      })
      fileReader.readAsDataURL(files[0])
      var pos = files[0].name.lastIndexOf(".");
      var ext = files[0].name.slice(pos);
      //var res = this.fullName.concat(ext);
      function fileUpload (file) {
        return new Promise((resolve, reject) => {
        var storageRef = firebase.storage().ref('docpics/' + file.name)
        storageRef.put(file).then(function(snapshot) {
          firebase.storage().ref('docpics/' + file.name).getDownloadURL().then(url => {
            var con = `<div><iframe style='width: 100%; height: 88vh' src='`+ url +`'></iframe></div>`
            resolve(con);
          })
        });
      });
      }
      //this.filename = res
      this.pdf = files[0]
      var newContent = await fileUpload(files[0]);

      this.content += newContent;
      this.pdf = null;

    },
    onEditorBlur(editor) {
      //console.log('editor blur!', editor)
    },
    onEditorChange(editor) {
      if (editor.html != this.oriContent) {
        this.savebtn = true
      }
      else {
        this.savebtn = false
      }
    },
    onEditorReady(editor) {
      //console.log('editor ready!', editor)
    },
    activate: function(contentus, id) {
      this.activeContent = id
      this.content = contentus
      this.editTit = contentus.title
      this.oriContent = contentus

  },
  addDoc() {
    db.collection("docs").add({
    title: this.docnew,
    content: ""
    })
    .then(docRef => {
        console.log("Document written with ID: ", docRef.id);
        this.dialog = false
    })
    .catch(function(error) {
        console.error("Error adding document: ", error);
    });
  },
  editDoc() {
    db.collection("docs").doc(this.activeContent).update({
      title: this.editTit
      })
      .then(() => {
          console.log("Document successfully updated!");
      })
      .catch(function(error) {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
      });
    },
    delDoc(id) {
      if(confirm ('Are you sure?')) {
        db.collection("docs").doc(id).delete().then(function() {
        }).catch(function(error) {
            console.error("Error removing document: ", error);
        });
      }
    },
  updateDoc() {
    db.collection("docs").doc(this.activeContent).update({
      content: this.content,
      })
      .then(() => {
          console.log("Document successfully updated!");
          this.snackbar = true
      })
      .catch(function(error) {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
      });
    },
  },
  computed: {
    editor() {
      return this.$refs.myTextEditor.quill
    },
    contentCode() {
      return hljs.highlightAuto(this.content).value
    }
  },
  mounted() {
    //console.log('this is my editor', this.editor.root.innerHTML)
    setTimeout(() => {
      this.content = ``
    }, 1300)
  }
}
</script>
