<template>
  <v-app >
<v-navigation-drawer
  v-model="left"
  temporary
  width="300"
  fixed
>
<v-list class="pa-1">
       <v-list-item avatar>
         <v-list-item-avatar>
           <img v-bind:src="photo">
         </v-list-item-avatar>

         <v-list-item-content>
           <v-list-item-title>{{ name }}</v-list-item-title>
         </v-list-item-content>
       </v-list-item>
     </v-list>

     <v-list class="pt-0" dense>
       <v-divider></v-divider>
       <div v-if="name">
       <v-list-group
          no-action
          sub-group
          v-if="admins.includes(email)"
          >



          <!--  -->
        <template v-slot:activator>
          <v-list-item>
            <v-list-item-action>
              <v-icon>fas fa-user-shield</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Admin</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>



        <v-list-group
          no-action
          sub-group
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Employee Management</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item to="Onboarding">
            <v-list-item-action>
              <v-icon>person_add</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Register new employee to HR</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="onBConsu">
            <v-list-item-action>
              <v-icon>person_add</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Register new consultant to HR</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="onBIntern">
            <v-list-item-action>
              <v-icon>person_add</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Register new intern to HR</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="Offboarding">
            <v-list-item-action>
              <v-icon>person_add_disabled</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Offboarding</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="internrequest">
            <v-list-item-action>
              <v-icon>person_add</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Intern Request</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="Transfer">
            <v-list-item-action>
              <v-icon>compare_arrows</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Transfer</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider inset></v-divider>
        </v-list-group>






        <v-list-item to="Adminnew">
          <v-list-item-action>
            <v-icon>far fa-newspaper</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>News</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="Appreciation" v-if="email == 'annika.hermansson@idealofsweden.com' || email == 'daniel.eriksson@idealofsweden.com' || email == 'linnea.borgstrom@idealofsweden.com'">
          <v-list-item-action>
            <v-icon>favorite_border</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Appreciation</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

     <v-list-item to="Admindoc">
          <v-list-item-action>
            <v-icon>far fa-file-alt</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Docs</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
  <!--

        <v-list-item to="Admincol">
          <v-list-item-action>
            <v-icon>fas fa-users</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Colleagues</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
-->





        <v-list-item to="adminmas">
          <v-list-item-action>
            <v-icon>calendar_today</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Calendar schedule</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="Adminval">
          <v-list-item-action>
            <v-icon>far fa-folder-open</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Admin Access</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider inset></v-divider>
      </v-list-group>
      </div>
<!--
      <v-list-group>
       <template v-slot:activator>
         <v-list-item>
           <v-list-item-action>
             <v-icon>airline_seat_flat</v-icon>
           </v-list-item-action>
           <v-list-item-content>
             <v-list-item-title>Massage</v-list-item-title>
           </v-list-item-content>
         </v-list-item>
       </template>

       <v-list-item to="massagenrkpg">
         <v-list-item-content>
           <v-list-item-title>Norrköping</v-list-item-title>
         </v-list-item-content>
       </v-list-item>

      <v-list-item to="massagesthlm">
         <v-list-item-content>
           <v-list-item-title>Stockholm</v-list-item-title>
         </v-list-item-content>
       </v-list-item>

      </v-list-group>


      -->

      </div>
      <v-list-item to="/Profile">
        <v-list-item-action>
          <v-icon>person</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>My Profile</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/AppreciationRequest">
        <v-list-item-action>
          <v-icon>favorite_border</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Appreciation Request</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-group
        no-action
        sub-group
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Onboarding</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item to="onBIntro">
          <v-list-item-action>
            <v-icon>favorite_border</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>My introduction</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="onBQuiz">
          <v-list-item-action>
            <v-icon>favorite_border</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>IDEAL Quiz</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider inset></v-divider>
      </v-list-group>

      <v-list-item to="/Statistics">
        <v-list-item-action>
          <v-icon>trending_up</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Statistics</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item target="blank" href="https://form.asana.com?k=3ucZMDvesh_XLB3jo55SRQ&d=62614433325898">
        <v-list-item-action>
          <v-icon>laptop_mac</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Support</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item target="blank" href="https://form.asana.com/?k=tbUjaOjoGFOj5UNQDIzJCw&d=62614433325898">
        <v-list-item-action>
          <v-icon>laptop_mac</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Landingpage Request</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item target="blank" href="https://form.asana.com/?k=6xgxm5bA8QjeAy_VnjPFeg&d=62614433325898">
        <v-list-item-action>
          <v-icon>Brush</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Design Request</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item target="blank" href="https://form.asana.com?hash=e43766d0ab44fd8846eea92a3df6bdd113e654dac7b70a48be8f2c6829925c04&id=1161411561916728">
        <v-list-item-action>
          <v-icon>how_to_vote</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Navision Requests</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

       <v-list-item @click="logOut">
         <v-list-item-action>
           <v-icon>power_settings_new</v-icon>
         </v-list-item-action>

         <v-list-item-content>
           <v-list-item-title>Log out</v-list-item-title>
         </v-list-item-content>
       </v-list-item>
     </v-list>
</v-navigation-drawer>

    <v-toolbar v-if="name" style="max-height: 60px">
      <v-btn icon @click.stop="left = !left">
        <v-icon>fas fa-bars</v-icon>
      </v-btn>
      <a href="/">
      <v-img
        :src="require('./assets/idealloggo.png')"
        class="ml-3"
        contain
        style="max-width: 250px"
      ></v-img>
    </a>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn to="/Calendar" text>Calendar</v-btn>
        <v-btn to="/Colleague" text>Colleagues</v-btn>
        <v-btn to="/Docs" text>Docs</v-btn>

      </v-toolbar-items>
      <v-btn fab icon>
        <a href="https://idealofsweden.onelogin.com">
          <v-avatar>
            <v-img src="https://firebasestorage.googleapis.com/v0/b/idos-4bb5f.appspot.com/o/onelogin.png?alt=media&token=34baceb8-de0e-44b8-8802-c0428e08d1ed"></v-img>
          </v-avatar>
        </a>
      </v-btn>
    </v-toolbar>

    <v-content>
      <router-view/>
    </v-content>
  </v-app>
</template>

<script src="https://www.gstatic.com/firebasejs/6.2.2/firebase-app.js"></script>

<script src="https://www.gstatic.com/firebasejs/6.2.2/firebase-auth.js"></script>
<script src="https://www.gstatic.com/firebasejs/6.2.2/firebase-firestore.js"></script>

<script>
import HelloWorld from './components/HelloWorld'
import firebase from 'firebase'
import axios from 'axios'

var firebaseConfig = {
  apiKey: "AIzaSyDBjNq1Vrwmt70aBXj4KIz9OPBJEE7_k80",
  authDomain: "idos-4bb5f.firebaseapp.com",
  databaseURL: "https://idos-4bb5f.firebaseio.com",
  projectId: "idos-4bb5f",
  storageBucket: "gs://idos-4bb5f.appspot.com",
  messagingSenderId: "604074735647",
  appId: "1:604074735647:web:4f88465d0f6c9f6c"
   };
firebase.initializeApp(firebaseConfig);
var db = firebase.firestore();

export default {
  name: 'App',
  components: {
    HelloWorld
  },
  data () {
    return {
      drawer: null,
      drawerRight: null,
      left: false,
      admins: [],
      photo: $cookies.get("aW50cmFuZXRjcmVkZW50aWFscw==").picture,
      name: $cookies.get("aW50cmFuZXRjcmVkZW50aWFscw==").fullname,
      email: $cookies.get("aW50cmFuZXRjcmVkZW50aWFscw==").email,
      datenow: ''
    }
  },
  created () {
    db.collection("admins")
      .onSnapshot(querySnapshot => {
        var ad = []
        this.admins = ad
          querySnapshot.forEach(doc => {
            var data = {
              'id': doc.id,
              'email': doc.data().email,
            }
              ad.push(doc.data().email);
          });
      });
  },
  mounted () {
    this.callFunction()
    if ($cookies.isKey("aW50cmFuZXRjcmVkZW50aWFscw==")) {
      this.validCheck()
    } else {
      console.log("No cookie found")
    }
  },
    methods: {
      callFunction: function () {
      this.datenow = Math.floor(Date.now()/1000);
      setTimeout(this.callFunction, 1000)
    },
    validCheck: function () {

      var cookie = $cookies.get("aW50cmFuZXRjcmVkZW50aWFscw==")

      if (this.datenow > cookie.depriTime) {
        axios({
          url: 'https://oauth2.googleapis.com/token',
          method: 'post',
          params: {
                   'refresh_token': cookie.refToken,
                   'client_id': '604074735647-tjk7oipvdlhs8db18711ng5elb66ius0.apps.googleusercontent.com',
                   'client_secret': 'bVeug-rZhVhM8IICSfkx2QS_',
                   'grant_type': 'refresh_token'
                  }
        }).then(response => {
          cookie.token = response.data.access_token;
          cookie.depriTime = Math.floor(Date.now()/1000) + 3600;
          $cookies.set('aW50cmFuZXRjcmVkZW50aWFscw==',cookie,Infinity);
          console.log('Token has been refreshed')
        }).catch(error  => {
          this.$gAuth.signOut()
          .then(() => {
            $cookies.remove('aW50cmFuZXRjcmVkZW50aWFscw==')
            this.$router.push({ path: `/login` })
          })
        })
      }
    setTimeout(this.validCheck, 1000)
  },
    logOut(){
      this.$gAuth.signOut()
      .then(() => {
        $cookies.remove('aW50cmFuZXRjcmVkZW50aWFscw==')
        this.$router.push({ path: `/login` })
        window.location.reload()
      })
      .catch(error  => {
        // On fail do something
        console.log(error)
      })
    },
  },
}
</script>
