<template>
  <div class="modal black--text" v-if="show">
    <div class="modal-content">
      <h1>Embed youtube videos</h1>
      <v-text-field
        v-model="url" id="url"
        solo
        label="Youtube URL"
      ></v-text-field>
      <footer class="modal-footer">
        <v-btn @click="insertVideo">Add</v-btn>
        <v-btn @click="show = false;">Close</v-btn>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: "",
      command: null,
      show: false
    };
  },
  computed: {

    youtubeID() {
      return this.youtubeParser(this.url);
    }
  },
  methods: {
    youtubeParser(url) {
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
      const match = url.match(regExp);
      return match && match[7].length === 11 ? match[7] : false;

    },
    showModal(command) {
      // Add the sent command
      this.command = command;
      this.show = true;
    },
    insertVideo() {
      // Some check can be done here, like if `youtubeID` is not false.
      const data = {
        command: this.command,
        data: {
          src: this.youtubeID
        }
      };
      console.log(this.command)
      this.$emit("onConfirm", data);
      this.show = false;
    }
  }
};
</script>

<style scoped>
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
}

.modal-footer {
  margin-top: 10px;
}

button + button {
  margin-left: 10px;
}
</style>
