<template>
  <v-container  grid-list-md>
   <v-layout row wrap>
     <v-flex xs3>
       <v-card>
         <v-list dark two-line style="background-color: #041A36; color:white; padding-right:20px; overflow-y:auto;">
           <v-list-item @click="remote = true">
            <v-list-item-content>
              <v-list-item-title>Remote Working Guidelines</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
           <!--
          <template v-for="(item, index) in items">

            <v-list-item
              :key="index"
              v-on:click="activate(item.contentus, item.id)"
            >
              <v-list-item-content>
                <v-list-item-title v-html="item.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template> -->
          <v-list-group v-for="(item, index) in items" :key="index" v-on:click="activate(item.contentus, item.id)">
           <template v-slot:activator>
             <v-list-item >
               <v-list-item-content>
                 <v-list-item-title v-html="item.title"></v-list-item-title>
               </v-list-item-content>
             </v-list-item>
           </template>

           <v-list-item v-for="(sub, index) in subItems" :key="index" @click="$vuetify.goTo(subActivate(sub.innerText), options)">
             <v-list-item-action >
               <v-icon>far fa-newspaper</v-icon>
             </v-list-item-action>
             <v-list-item-content>
               <v-list-item-title v-html="sub.innerText"></v-list-item-title>
             </v-list-item-content>
           </v-list-item>
         </v-list-group>
        </v-list>
       </v-card>
     </v-flex>
     <v-flex xs9>
       <v-card dark raised style="background-color: #041A36; color:white">
         <v-container>
          <div v-if="remote === false" class="white--text">
           <quill-editor
                class="wh"
                ref="myTextEditor"
                :content="content"
                @change="onEditorChange($event)"
                :options="editorOption"
                :disabled='disabled'
                >
           </quill-editor>
         </div>
     <div v-if="remote === true">
       <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vTcAzTJ1LWx_bHNXoENeZhR5aqnAxvhppElLrLuWhnMAEIaBC39UE3IiVAsyrkd8A/embed?start=false&loop=false&delayms=10000" frameborder="0" width="100%" height="500vh" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
     </div>
       </v-container>
       </v-card>
     </v-flex>
   </v-layout>
 </v-container>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import Quill from 'quill'
import 'quill/dist/quill.bubble.css'
import hljs from 'highlight.js'
import { quillEditor } from 'vue-quill-editor'
import firebase from 'firebase'

var db = firebase.firestore();

var quill = new Quill('editor', {
  theme: 'bubble',  // Specify theme in configuration
  readOnly: true,
});

export default {
  components: {
    quillEditor
  },
  data() {
    return {
      name: '01-example',
      activeContent: '',
      oriContent:'',
      items: [],
      subItems: [],
      disabled: true,
      remote: true,
      content: `<h2 class="ql-align-center"><span>Documentation loading..</span></h2>`,
      editorOption: {
        theme: 'bubble',
        readOnly: true,
        placeholder: "输入任何内容，支持html",
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['link', 'image'],
            ['clean']
          ]
        }
      }
    }
  },
  created () {
    db.collection("docs")
      .onSnapshot(querySnapshot => {
        var dc = []
        this.items = dc
          querySnapshot.forEach(doc => {
            var data = {
              'id': doc.id,
              'title': doc.data().title,
              'contentus': doc.data().content,
            }
              dc.push(data);
          });
      });
  },
  methods: {
    subActivate: function(val) {
      var hTags = document.getElementsByTagName("h1", "h2", "h3", "h4");
      for (var i = 0; i < hTags.length; i++) {
        if (hTags[i].textContent == val) {
          return hTags[i].offsetTop;
        }
      }
    },
    activate: function(contentus, id) {
      this.activeContent = id
      this.remote = false
      this.content = contentus
      this.oriContent = contentus

      var el = document.createElement( 'html' );
      el.innerHTML = contentus;
      this.subItems = el.getElementsByTagName("h1", "h2", "h3", "h4")

    },
     onEditorChange(editor) {
        var el = document.createElement( 'html' );
        setTimeout(function () {
          el.innerHTML = editor.html;
          this.subItems = el.getElementsByTagName("h1", "h2", "h3")
        }, 500);
      }
  },
  computed: {
    options () {
      return {
        duration: 1000,
        offset: 0
      }
    },
    editor() {
      return this.$refs.myTextEditor.quill
    },
    contentCode() {
      return hljs.highlightAuto(this.content).value
    }
  }
}
</script>
<style>
.wh span {
  color: white !important;
}
.wh strong {
  color: white !important;
}
.wh p {
  color: white !important;
}
.wh h1 {
  color: white !important;
}
.wh h2 {
  color: white !important;
}
.wh h3 {
  color: white !important;
}
.wh h4 {
  color: white !important;
}
.wh h5 {
  color: white !important;
}
</style>
