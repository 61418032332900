<template>
  <div>
    <v-container
      class="grey lighten-5 mt-5 mb-5"
    >
      <v-row
        align="center"
        no-gutters
        style="height: 150px;"
      >
          <v-form
          @submit.prevent="addAdmin"
          ref="form">
            <v-text-field
              solo
              v-model="newAdm"
            ></v-text-field>
            <v-btn class="mb-5" color="success" Type="submit">Add Manager</v-btn>
          </v-form>
      </v-row>
      <v-row>
        <v-list>
          <template v-for="admin in admins">

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title v-html="admin.email"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon ripple v-on:click="deleteAdmin(admin.id)">
                  <v-icon color="red">delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </v-row>
    </v-container>
  </div>
</template>


<script>
import firebase from 'firebase'

var db = firebase.firestore();
  export default {
    data () {
      return {
        alignments: {},
        admins: {},
      }
    },
    created () {
      db.collection("admins")
        .onSnapshot(querySnapshot => {
          var ad = []
          this.admins = ad
            querySnapshot.forEach(doc => {
              var data = {
                'id': doc.id,
                'email': doc.data().email,
              }
                ad.push(data);
            });
        });
    },
    methods: {
      addAdmin () {
      db.collection('admins').add({
        email: this.newAdm
      }).then(() => {
        this.$refs.form.reset()
      })
    },
      deleteAdmin: function(id) {
        if(confirm ('Are you sure?')) {
          db.collection("admins").doc(id).delete().then(function() {
          }).catch(function(error) {
              console.error("Error removing document: ", error);
          });
        }
      }
    }
  }
</script>
