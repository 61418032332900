<div style="display:inline-block" id="kf-embed-container-9789b4c1a4dffc9691d239414657dfd0"></div>
<script type="text/javascript" src="https://embed.klipfolio.com/a/js/embed.api"></script>
<script type="text/javascript">
KF.embed.embedKlip({
   profile : "9789b4c1a4dffc9691d239414657dfd0",
   settings : {"width":1832,"theme":"light","borderStyle":"round","borderColor":"#cccccc"},
   title : "Order Budget vs Forecast per hour  - Today"
});
</script>
<script>
export default {
  name: 'klip',
  data () {
    return {
    }
  },
}
</script>
