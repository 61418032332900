<template>
  <v-container>
    <v-snackbar
   v-model="showSnack"
   color="success"
   top
 >
   {{ text }}
   <v-btn
     text
     @click="showSnack = false"
   >
     Close
   </v-btn>
 </v-snackbar>
  <v-layout class="fill-height">
    <v-flex md12 pt5>
      <v-sheet height="64">
        <v-toolbar flat color="white">
          <v-btn outlined class="mr-4" @click="setToday">
            Today
          </v-btn>
          <v-btn fab small @click="prev">
            <v-icon small>fas fa-chevron-left</i></v-icon>
          </v-btn>
          <v-btn fab small @click="next">
            <v-icon small>fas fa-chevron-right</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ focus }}</v-toolbar-title>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="events"
          :event-color="getEventColor"
          :event-margin-bottom="3"
          :now="today"
          type="month"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange"
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          full-width
          offset-x
        >
          <v-card
            min-width="350px"
          >
            <v-toolbar
              :color="selectedEvent.color"
              dark
            >
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-container v-if="selectedEvent.type === 'massage'">
              <div v-if="selectedEvent.entries.some(obj => obj.Assignee === this.myEmail)">
                <div v-for="check in selectedEvent.entries.filter(ent => ent.Assignee === this.myEmail)">
                <v-row justify="center">
                  <p >You have a massage booked for {{ check.title }}</p>
                </v-row>
                <v-row justify="center">
                  <v-btn @click="delApp(selectedEvent, check)" color="red" class="white--text">Cancel my appointment</v-btn>
                </v-row>
              </div>
              </div>
              <div v-else>
              <v-radio-group v-model="radioGroup">
                <div v-for="(entry, index) in selectedEvent.entries">

                  <div v-if="selectedEvent.location == 'sthlm' && bookedSthlm >= 1">
                    <v-radio
                      :label="entry.title + ' ' + entry.Assignee"
                      disabled
                    ></v-radio>
                  </div>
                  <div v-else-if="selectedEvent.location == 'nrkpg' && bookedNrkpg >= 1">
                    <v-radio
                      :label="entry.title + ' ' + entry.Assignee"
                      disabled
                    ></v-radio>
                  </div>
                  <div v-else-if="entry.Assignee === '' && selectedEvent.location == 'sthlm' && bookedSthlm == 0">
                    <v-radio
                      :label="entry.title"
                      :value="entry"
                      :key="index"
                    ></v-radio>
                  </div>
                  <div v-else-if="entry.Assignee === '' && selectedEvent.location == 'nrkpg' && bookedNrkpg == 0">
                    <v-radio
                      :label="entry.title"
                      :value="entry"
                      :key="index"
                    ></v-radio>
                  </div>
                  <div v-else>
                    <v-radio
                      :label="entry.title + ' ' + entry.Assignee"
                      disabled
                    ></v-radio>
                  </div>
              </div>
              </v-radio-group>
              <v-card-actions>
                <v-btn
                  color="secondary"
                  @click="selectedOpen = false"
                >
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                v-if="radioGroup === null"
                  disabled
                  color="success"
                  @click="selectedOpen = false"
                >
                  Sign up
                </v-btn>
                <v-btn
                v-else
                  solo
                  color="success"
                  @click="register(selectedEvent)"
                >
                  Sign up
                </v-btn>
              </v-card-actions>
            </div>
            </v-container>

            <v-container v-if="selectedEvent.type === 'workout'">
              <div class="body-2">
                <p>This workout will be held {{ selectedEvent.time }}</p>
                <p>Responsible is {{ selectedEvent.resp }}</p>
                <p>{{ selectedEvent.avseats }}/{{ selectedEvent.seats }} spots available.This is the attendee list:</p>
                <ul>
                  <li v-for="(atte, index) in selectedEvent.attendees"  :key="index">{{ atte }}</li>
                </ul>
                <div v-if="selectedEvent.attendees.includes(this.myEmail)">
                  <p>You are signed up for this workout</p>
                  <v-btn @click="delAppWok(selectedEvent)" color="red" class="white--text">Remove me from this workout</v-btn>
                </div>
              </div>
              <v-card-actions>
                <v-btn

                  color="secondary"
                  @click="selectedOpen = false"
                >
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="selectedEvent.attendees.includes(this.myEmail) != true"
                  solo
                  color="success"
                  @click="registerwok(selectedEvent)"
                >
                  Sign up
                </v-btn>
              </v-card-actions>
            </v-container>

            <v-container v-if="selectedEvent.type === 'other'">
              <div class="body-2" style="max-width:300px">
                <p>{{ selectedEvent.startdisp }} - {{ selectedEvent.stopdisp }} | {{ selectedEvent.location }}</p>
                <p>{{ selectedEvent.details }}</p>
              </div>
              <v-card-actions>
                <v-btn
                  color="secondary"
                  @click="selectedOpen = false"
                >
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  solo
                  color="success"
                  @click="registerotr(selectedEvent)">
                  Save to my calendar
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-flex>
  </v-layout>
</v-container>
</template>

<script>
import firebase from 'firebase'
import firestore from 'firebase'
import axios from 'axios'

var db = firebase.firestore();

  export default {
    data: () => ({
      myEmail: $cookies.get("aW50cmFuZXRjcmVkZW50aWFscw==").email,
      today: '2019-10-01',
      focus: '2019-10-01',
      radioGroup: null,
      showSnack: false,
      bookedSthlm: 0,
      bookedNrkpg: 0,
      myEvent: [],
      text: null,
      type: 'month',
      typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day',
        '4day': '4 Days',
      },
      start: null,
      end: null,
      selectedEvent: {},
      skick: {

      },
      selectedElement: null,
      selectedOpen: false,
      events: [],
    }),
    async created () {
      this.today = new Date().toISOString().slice(0,10);
      this.focus = this.today

            db.collection('calendar').get().then(querySnapshot => {

              querySnapshot.forEach((doc) => {
                        //        WORKOUT IMPORT
                if (doc.data().type === "workout") {
                  var startTime = new Date(doc.data().startTime*1000).toISOString().slice(0,10)
                  var avseats =  doc.data().seats - doc.data().attendees.length
                  var id = doc.id
                  var col = ""
                  if (doc.data().location === "nrkpg") {
                    col = "orange accent-2"
                  } else {
                    col = "light-blue accent-2"
                  }
                  var data = {
                    'time': doc.data().time,
                    'id': id,
                    'bookable': false,
                    'type': doc.data().type,
                    'resp': doc.data().wokemail,
                    'location': doc.data().location,
                    'start': startTime,
                    'details': doc.data().name,
                    'name': doc.data().name + " || " +  doc.data().title,
                    'color': col,
                    'str': doc.data().startTime,
                    'stp': doc.data().stopTime,
                    'sr': doc.data().str,
                    'sp': doc.data().stp,
                    'avseats': avseats,
                    'seats': doc.data().seats,
                    'attendees':doc.data().attendees,
                  }
                  this.events.push(data)
                }
                                  //        MASSAGE IMPORT
                if (doc.data().type === "massage") {
                  var startTime = new Date(doc.data().startTime*1000).toISOString().slice(0,10)
                  var id = doc.id
                  var info = []
                  var mcol = ""
                  if (doc.data().location === "nrkpg") {
                    mcol = "orange accent-3"
                  } else {
                    mcol = "light-blue accent-3"
                  }
                  Object.values(doc.data()).forEach(key => {
                    if (key.title) {
                      info.push(key)
                    }
                    if (key.Assignee === this.myEmail) {
                      this.myEvent.push(key)
                    }
                  });
                  var data = {
                    'time': doc.data().time,
                    'id': id,
                    'type': 'massage',
                    'bookable': false,
                    'location': doc.data().location,
                    'str': doc.data().startTime,
                    'stp': doc.data().stopTime,
                    'start': startTime,
                    'details': doc.data().name,
                    'name': doc.data().name,
                    'color': mcol,
                    'entries':info.sort((a, b) => (a.indx < b.indx) ? 1 : -1)
                  }
                  this.events.push(data)
                }
                //        OTHER IMPORT
                if (doc.data().type === "other") {
                  var startTime = new Date(doc.data().startTime*1000).toISOString().slice(0,10)
                  var stopTime = new Date(doc.data().stopTime*1000).toISOString().slice(0,10)
                  var id = doc.id
                  var data = {
                    'startdisp': doc.data().start,
                    'stopdisp': doc.data().stop,
                    'id': id,
                    'type': 'other',
                    'location': doc.data().location,
                    'start': startTime,
                    'stop': stopTime,
                    'startconf': doc.data().startTime,
                    'stopconf': doc.data().stopTime,
                    'str': doc.data().startTime,
                    'stp': doc.data().stopTime,
                    'details': doc.data().desc,
                    'name': doc.data().name,
                    'color': doc.data().color
                  }
                  this.events.push(data)
                }
                var startTime = new Date(doc.data().startTime*1000).toISOString().slice(0,10)
                });
            });
            await new Promise(resolve => setTimeout(resolve, 2000));
            var eve = this.events
            for (var i = 0; i < eve.length; i++) {
              if (eve[i].type == "massage") {
                if (eve[i].location == "sthlm") {
                  for (var p = 0; p < eve[i].entries.length; p++) {
                    if (eve[i].entries[p].Assignee == this.myEmail) {
                      this.bookedSthlm += 1
                    }
                  }
                } else {
                  for (var p = 0; p < eve[i].entries.length; p++) {
                    if (eve[i].entries[p].Assignee == this.myEmail) {
                      this.bookedNrkpg += 1
                    }
                  }
                }
              }
            }
            console.log("STHLM: ",this.bookedSthlm,"NRKPG: ",this.bookedNrkpg)
    },
    mounted () {
      this.$refs.calendar.checkChange()

    },
    computed () {

    },
    methods: {
      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      getEventColor (event) {
        return event.color
      },
      register (eve) {
              axios({
                url: 'https://www.googleapis.com/calendar/v3/calendars/primary/events',
                method: 'post',
                headers: {
                     'Authorization': 'Bearer ' + $cookies.get("aW50cmFuZXRjcmVkZW50aWFscw==").token,
                },
                data: {
                     'end': {
                          'dateTime': this.radioGroup.stop,
                          'timeZone': 'Europe/Stockholm'
                     },
                     'start': {
                          'dateTime': this.radioGroup.start,
                          'timeZone': 'Europe/Stockholm'
                     },
                     'summary': 'Massage'
                },
              }).then(response =>  {
                db.collection("calendar").doc(eve.id).update({
                      [this.radioGroup.title]: {
                        "Assignee": $cookies.get("aW50cmFuZXRjcmVkZW50aWFscw==").email,
                        "start": this.radioGroup.start,
                        "stop": this.radioGroup.stop,
                        "title": this.radioGroup.title,
                        "indx": this.radioGroup.indx,
                        "calEventId": response.data.id
                      }
                    }).then(response =>  {
                          this.showSnack = true
                          this.text = "You have now been signed up for a massage, the event has been added to your calendar."
                          window.location.reload()
                    })
              })
      },
      registerwok (eve) {
          eve.attendees.push($cookies.get("aW50cmFuZXRjcmVkZW50aWFscw==").email)
              axios({
                url: 'https://www.googleapis.com/calendar/v3/calendars/primary/events',
                method: 'post',
                headers: {
                     'Authorization': 'Bearer ' + $cookies.get("aW50cmFuZXRjcmVkZW50aWFscw==").token,
                },
                data: {
                     'end': {
                          'dateTime': eve.sp,
                          'timeZone': 'Europe/Stockholm'
                     },
                     'start': {
                          'dateTime': eve.sr,
                          'timeZone': 'Europe/Stockholm'
                     },
                     'summary': 'Workout'
                    },
              }).then(response =>  {
                db.collection("calendar").doc(eve.id).update({
                      'attendees': eve.attendees
                    })
              }).then(response =>  {
                    this.showSnack = true
                    this.text = "You have now been signed up for this workout, the event has been added to your calendar."
              })
      },
      registerotr (eve) {

        var d = new Date(eve.startconf*1000);
        var str = d.toISOString();
        var b = new Date(eve.stopconf*1000);
        var stp = b.toISOString();
              axios({
                url: 'https://www.googleapis.com/calendar/v3/calendars/primary/events',
                method: 'post',
                headers: {
                     'Authorization': 'Bearer ' + $cookies.get("aW50cmFuZXRjcmVkZW50aWFscw==").token,
                },
                data: {
                     'end': {
                          'dateTime': stp
                     },
                     'start': {
                          'dateTime': str
                     },
                     'summary': eve.name,
                     'description': eve.details
                    },
              }).then(response =>  {
                    this.showSnack = true
                    this.text = "The event has been added to your calendar."
              })
      },
      delApp (eve, ent) {
        db.collection("calendar").doc(eve.id).update({
              [ent.title]: {
                "Assignee": "",
                "start": ent.start,
                "stop": ent.stop,
                "indx": ent.indx,
                "title": ent.title
              }
            }).then((docRef) => {
              axios({
                url: 'https://www.googleapis.com/calendar/v3/calendars/primary/events/' + ent.calEventId + '?sendUpdates=none',
                method: 'DELETE',
                headers: {
                     'Authorization': 'Bearer ' + $cookies.get("aW50cmFuZXRjcmVkZW50aWFscw==").token
                }
              }).then(response =>  {
                window.location.reload()
              })
        })
      },
      delAppWok (eve) {
        var result = eve.attendees.filter(attendee => attendee != this.myEmail)
        db.collection("calendar").doc(eve.id).update({
              'attendees': result
            }).then((docRef) => {
            this.myEvent = false
            window.location.reload()
        })
      },
      setToday () {
        this.focus = this.today
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          setTimeout(() => this.selectedOpen = true, 10)
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          setTimeout(open, 10)
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
      updateRange ({ start, end }) {
        // You could load events from an outside source (like database) now that we have the start and end dates on the calendar
        this.start = start
        this.end = end
      },
      nth (d) {
        return d > 3 && d < 21
          ? 'th'
          : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10]
      },
    },
  }
</script>
