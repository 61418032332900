<template>
<v-content>
  <v-img
  dark
  height="100%"
  src='https://firebasestorage.googleapis.com/v0/b/idos-4bb5f.appspot.com/o/LeonieIOS28.jpg?alt=media&token=df8e2998-09b4-4c98-98e5-9783c144e730'
>
<div class="text-center" style="margin-top: 24%">
  <v-btn @click="handleClickSignIn" large color="red">
      <v-icon color="white" >fab fa-google</v-icon> <p class="ml-4 pt-2 white--text">Login with Google</p>
  </v-btn>
</div>
</v-img>
</v-content>
</template>
<script src="https://apis.google.com/js/platform.js" async defer></script>

<script>
import firebase from 'firebase'
import axios from 'axios'

export default {
  name: 'login',
  data () {
    return {
      tok: 'null',
      isInit: false,
      isSignIn: false
    }
  },
  mounted(){
    let that = this
    let checkGauthLoad = setInterval(function(){
      that.isInit = that.$gAuth.isInit
      that.isSignIn = that.$gAuth.isAuthorized
      if(that.isInit) clearInterval(checkGauthLoad)
    }, 1000);
  },
  methods: {
    handleClickSignIn(){
      firebase.auth().signInAnonymously().catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // ...
      });
      this.$gAuth.getAuthCode()
      .then(authCode => {
        //console.log(window.location.href.split('/#')[0]);
        axios({
          url: 'https://oauth2.googleapis.com/token',
          method: 'post',
          params: {
                   'code': authCode,
                   'client_id': '604074735647-tjk7oipvdlhs8db18711ng5elb66ius0.apps.googleusercontent.com',
                   'client_secret': 'bVeug-rZhVhM8IICSfkx2QS_',
                   'redirect_uri': "https://idealofsweden.team",
                   'grant_type': 'authorization_code'
                  }
        }).then(response => {
          var token = response.data.access_token;
          var refToken = response.data.refresh_token;
          var depriTime = Math.floor(Date.now()/1000) + 3600;

          axios({
            url: 'https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=' + token,
            method: 'get',
          }).then(response => {
              var usr = {
                email: response.data.email,
                id: response.data.id,
                firstname: response.data.given_name,
                lastname: response.data.family_name,
                fullname: response.data.name,
                picture: response.data.picture,
                token: token,
                refToken: refToken,
                depriTime: depriTime
              }
              this.$cookies.set('aW50cmFuZXRjcmVkZW50aWFscw==',usr,'180d');
              this.$router.push({ path: `/` })
              window.location.reload()
      })
      //    this.$router.push({ path: `/` })
    })
  })


      /*
      this.$gAuth.signIn()
      .then(GoogleUser => {
        // On success do something, refer to https://developers.google.com/api-client-library/javascript/reference/referencedocs#googleusergetid
        var email = GoogleUser.w3.U3;
        var id = GoogleUser.w3.Eea;
        var firstname = GoogleUser.w3.ofa;
        var lastname = GoogleUser.w3.wea;
        var fullname = GoogleUser.w3.ig;
        var picture = GoogleUser.w3.Paa;

        localStorage.setItem('email', email)
        localStorage.setItem('id', id)
        localStorage.setItem('firstname', firstname)
        localStorage.setItem('lastname', lastname)
        localStorage.setItem('fullname', fullname)
        localStorage.setItem('picture', picture)
        this.isSignIn = this.$gAuth.isAuthorized
      }).then(response => {
        this.$gAuth.getAuthCode()
        .then(authCode => {
          console.log(authCode)
          axios({
            url: 'https://oauth2.googleapis.com/token',
            method: 'post',
            params: {
                     'code': authCode,
                     'client_id': '604074735647-tjk7oipvdlhs8db18711ng5elb66ius0.apps.googleusercontent.com',
                     'client_secret': 'bVeug-rZhVhM8IICSfkx2QS_',
                     'redirect_uri': 'http://localhost:8080',
                     'grant_type': 'authorization_code'
                    }
          }).then(response => {
            var token = response.data.access_token;
            var refToken = response.data.refresh_token;
            var depriTime = Math.floor(Date.now()/1000) + 3600;

            localStorage.setItem('token', token)
            localStorage.setItem('refToken', refToken)
            localStorage.setItem('depri', depriTime)
            this.$router.push({ path: `/` })
      })
    })
  }).catch(error  => {
        // On fail do something
})*/
    },
  }
}
</script>
