<template>
</template>
<script>
import KJUR from 'jsrsasign'
  export default {
    data: () => ({
      date: null,
    }),
    created(){
      var pHeader = {"alg":"RS256","typ":"JWT"}
      var sHeader = JSON.stringify(pHeader);
      var key = "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDLCCJQk/oBYTn5\nQ1J08vP1vLRWAKBB20hhr+8yhC3/u9VQXG1vc+0PyIr5dIcmLOXJfe537Ou0Dpfy\n+r931HDBVwJaJoYNEWhY9Wv5lrw1RJs8kK8enH1iX31Qkc5+VbpArD1QF8Nn4bsE\n0A2HVFzIOaWjsIvK3Htx8VXW0eTNpXpSS9H3/rhJPg1Y48y3LVNf4ibyrdgmH/qF\nULRJmHtBwmnwoWFNyCkRB7ZXAX6hHPGQ6ItH3KWJQ8bFtQ4wibkrzuQMv+xysXG3\nvQA1ybndxq3RPeO4gNgzvHIZTjYJBYuNC5rkGzfGEiVuNzpcsrYVLMg+ZwCCLEPD\n+hqY9X1hAgMBAAECggEABTRgLNCeSArGAa3IH1dbCaGcvdemRAnrARYRe6oHw9Z8\n0WKNK549JXpulZZ3IHNmMJQZ2Nkp8aOuIXxsDBZWDZ69YLI8HXmkwyMHcY57pKHt\nmM/crVw1mp/4Bmi2cmagL6g6qiLxz2fp82Wh8yYmert/k4KjEsA0KeeV27vlsZ/Q\nkrFZPrC5oSTP5OpguJwOZ/A661h503Z/zgnpqOjhm0sLHPcEkt5revBKNlvBIARM\nejf7e2HRjrOcJV5uC/s2Muop7pmorqWWrVFTmFgLfgX4dJoEvP0s7yr4AjqFnTop\nv99g8B8Ysbd1bptMMPjFKx0bX+nV18LdXkxnr2dMAQKBgQD8xY6ExyRgbiFADiki\nc71MirrFLnoUITGjthldykHnALl0te5JwcWRi2TTJHI8rZh6o3j1vg/ciaiwe3kL\n7n8/GEmPGwmlsSPh2wPU9kq5qiUbsHupwnrwVleOYfqMRCas1jEJVMw1+KpvqyZX\nWkG0AT8j0Nr6nDbNd4STrqvKOQKBgQDNn/OPA3Ks54pbqD/mwrWpk4h4Yik5qqwD\nOQL2+0s5aI4TtEeU0kI74u2u/AMlVqch0e/vPDE2oiu4Ri9MQKFOed2Vb/IeexyZ\nK/X7c1xIa3wDG4Fpp7Zf44ENlz72m3T4AQs0/QSFVepHH5o2eSbt+zNbvTXQBFPE\nI/XVkqvsaQKBgQD0AUEyq4pSC/3M9959g3yZBtKExfjStbhVcNPL1hMIC4r7PyRn\nzsYJWaZarDQanvkyadu6XV6yZbCRrJ+NBQ2QusFHg7IsYHNi8ykfyyEXWUe5EwRN\nO4+xsaeTqsFR5ffvoFR1zYXUE9dzNKQubtI+EXYB/PtmMKY2cclpRpRPAQKBgQCC\nRUPY5yZPxKEzUHSrjc/pjz52hjn3/tjbcdMfBjWyOTtJ4H05G7w6lJpgseiMT0mz\nc32+4zOKmVN5e32boZaQUUTA2BtclEKaB+/fTka42t/GWDa06TvxpACuy5NoSTTh\nqkefWmzyzGuQg8T/neG8A9mV1VmyYAtxyBaHKFUjgQKBgHsMG7xOZGTFR6ob68JS\nPmjeOR3kKC8vIRd7usWi1w9ZvP3cY1s5hgyLyUjc+KN+eeFLP20nugHzPklDF2/v\nlwrYh8BdJzEAIOXBNI5PTZ/fLey6eM1Ep5cd7oflOMLNHSyo5tawr0c14MLYvsw/\n3g4txWFg8v4ibK/NafsFrdSf\n-----END PRIVATE KEY-----\n";`4616`
      var pClaim = {
        "aud": "https://oauth2.googleapis.com/token",
        "scope": "https://www.googleapis.com/auth/admin.directory.user",
        "iss": "idos-425@intranet-261309.iam.gserviceaccount.com",
        "exp": Math.floor(Date.now()/1000)+3600,
        "iat": Math.floor(Date.now()/1000)
        };
      var sClaim = JSON.stringify(pClaim);

      var sJWS = KJUR.jws.JWS.sign(null, sHeader, sClaim, key);
      console.log(sJWS)
    },
  }
</script>
