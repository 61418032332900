import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Login from './views/Login.vue'
import Vault from './views/Vault.vue'
import Docs from './views/Docs.vue'
import Admindoc from './views/Admindoc.vue'
import Admincol from './views/Admincol.vue'
import Adminval from './views/Adminval.vue'
import Adminnew from './views/Adminnew.vue'
import adminmas from './views/adminmas.vue'

import Onboarding from './views/Onboarding.vue'
import onBConsu from './views/onBConsu.vue'
import onBIntro from './views/onBIntro.vue'
import onBQuiz from './views/onBQuiz.vue'

import onBIntern from './views/onBIntern.vue'
import AppreciationRequest from './views/AppreciationRequest.vue'
import Offboarding from './views/Offboarding.vue'
import support from './views/support.vue'
import Transfer from './views/Transfer.vue'
import requests from './views/requests.vue'
import internRequest from './views/internRequest.vue'
import Templates from './views/Templates.vue'
import Appreciation from './views/Appreciation.vue'

import Colleague from './views/Colleague.vue'
import Profile from './views/Profile.vue'
import Employee from './views/Employee.vue'
import Calendar from './views/Calendar.vue'
import Statistics from './views/Statistics.vue'
import firebase from 'firebase'
import 'firebase/auth'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,

      beforeEnter: (to, from, next) => {
      if ($cookies.isKey("aW50cmFuZXRjcmVkZW50aWFscw==")) {
        next()
      }
      else {
        next({
          path: "/Login"
        })
      }
    }
    },
    {
      path: '/Login',
      name: 'Login',
      component: Login
    },
    {
      path: '/Docs',
      name: 'Docs',
      component: Docs,
      beforeEnter: (to, from, next) => {
      if ($cookies.isKey("aW50cmFuZXRjcmVkZW50aWFscw==")) {
        next()
      }
      else {
        next({
          path: "/Login"
        })
      }
    }
    },
    {
      path: '/onBConsu',
      name: 'onBConsu',
      component: onBConsu,
      beforeEnter: (to, from, next) => {
      if ($cookies.isKey("aW50cmFuZXRjcmVkZW50aWFscw==")) {
        next()
      }
      else {
        next({
          path: "/Login"
        })
      }
    }
    },
    {
      path: '/onBIntro',
      name: 'onBIntro',
      component: onBIntro,
      beforeEnter: (to, from, next) => {
      if ($cookies.isKey("aW50cmFuZXRjcmVkZW50aWFscw==")) {
        next()
      }
      else {
        next({
          path: "/Login"
        })
      }
    }
    },
    {
      path: '/onBQuiz',
      name: 'onBQuiz',
      component: onBQuiz,
      beforeEnter: (to, from, next) => {
      if ($cookies.isKey("aW50cmFuZXRjcmVkZW50aWFscw==")) {
        next()
      }
      else {
        next({
          path: "/Login"
        })
      }
    }
    },
    {
      path: '/support',
      name: 'support',
      component: support,
      beforeEnter: (to, from, next) => {
      if ($cookies.isKey("aW50cmFuZXRjcmVkZW50aWFscw==")) {
        next()
      }
      else {
        next({
          path: "/Login"
        })
      }
    }
    },
    {
      path: '/onBIntern',
      name: 'onBIntern',
      component: onBIntern,
      beforeEnter: (to, from, next) => {
      if ($cookies.isKey("aW50cmFuZXRjcmVkZW50aWFscw==")) {
        next()
      }
      else {
        next({
          path: "/Login"
        })
      }
    }
    },
    {
      path: '/AppreciationRequest',
      name: 'AppreciationRequest',
      component: AppreciationRequest,
      beforeEnter: (to, from, next) => {
      if ($cookies.isKey("aW50cmFuZXRjcmVkZW50aWFscw==")) {
        next()
      }
      else {
        next({
          path: "/Login"
        })
      }
    }
    },
    {
      path: '/Profile',
      name: 'Profile',
      component: Profile,
      beforeEnter: (to, from, next) => {
      if ($cookies.isKey("aW50cmFuZXRjcmVkZW50aWFscw==")) {
        next()
      }
      else {
        next({
          path: "/Login"
        })
      }
    }
    },
    {
      path: '/requests',
      name: 'requests',
      component: requests,
      beforeEnter: (to, from, next) => {
      if ($cookies.isKey("aW50cmFuZXRjcmVkZW50aWFscw==")) {
        next()
      }
      else {
        next({
          path: "/Login"
        })
      }
    }
    },
    {
      path: '/internrequest',
      name: 'internRequest',
      component: internRequest,
      beforeEnter: (to, from, next) => {
      if ($cookies.isKey("aW50cmFuZXRjcmVkZW50aWFscw==")) {
        next()
      }
      else {
        next({
          path: "/Login"
        })
      }
    }
    },
    {
      path: '/Vault',
      name: 'Vault',
      component: Vault,
      beforeEnter: (to, from, next) => {
      if ($cookies.isKey("aW50cmFuZXRjcmVkZW50aWFscw==")) {
        next()
      }
      else {
        next({
          path: "/Login"
        })
      }
    }
    },
    {
      path: '/Calendar',
      name: 'Calendar',
      component: Calendar,
      beforeEnter: (to, from, next) => {
      if ($cookies.isKey("aW50cmFuZXRjcmVkZW50aWFscw==")) {
        next()
      }
      else {
        next({
          path: "/Login"
        })
      }
    }
    },
    {
      path: '/Appreciation',
      name: 'Appreciation',
      component: Appreciation,
      beforeEnter: (to, from, next) => {
      if ($cookies.isKey("aW50cmFuZXRjcmVkZW50aWFscw==")) {
        next()
      }
      else {
        next({
          path: "/Login"
        })
      }
    }
    },
    {
      path: '/Admindoc',
      name: 'Admindoc',
      component: Admindoc,
      beforeEnter: (to, from, next) => {
      if ($cookies.isKey("aW50cmFuZXRjcmVkZW50aWFscw==")) {
        next()
      }
      else {
        next({
          path: "/Login"
        })
      }
    }
    },
    {
      path: '/Adminnew',
      name: 'Adminnew',
      component: Adminnew,
      beforeEnter: (to, from, next) => {
      if ($cookies.isKey("aW50cmFuZXRjcmVkZW50aWFscw==")) {
        next()
      }
      else {
        next({
          path: "/Login"
        })
      }
    }
    },
    {
      path: '/Statistics',
      name: 'Statistics',
      component: Statistics,
      beforeEnter: (to, from, next) => {
      if ($cookies.isKey("aW50cmFuZXRjcmVkZW50aWFscw==")) {
        next()
      }
      else {
        next({
          path: "/Login"
        })
      }
    }
    },
    {
      path: '/adminmas',
      name: 'adminmas',
      component: adminmas,
      beforeEnter: (to, from, next) => {
      if ($cookies.isKey("aW50cmFuZXRjcmVkZW50aWFscw==")) {
        next()
      }
      else {
        next({
          path: "/Login"
        })
      }
    }
    },
    {
      path: '/Admincol',
      name: 'Admincol',
      component: Admincol
    },
    {
      path: '/Adminval',
      name: 'Adminval',
      component: Adminval
    },
    {
      path: '/Colleague',
      name: 'Colleague',
      component: Colleague,
      beforeEnter: (to, from, next) => {
      if ($cookies.isKey("aW50cmFuZXRjcmVkZW50aWFscw==")) {
        next()
      }
      else {
        next({
          path: "/Login"
        })
      }
    }
    },
    {
      path: '/Colleague/:userKey',
      name: 'Employee',
      component: Employee,
      beforeEnter: (to, from, next) => {
      if ($cookies.isKey("aW50cmFuZXRjcmVkZW50aWFscw==")) {
        next()
      }
      else {
        next({
          path: "/Login"
        })
      }
    }
    },
    {
      path: '/Onboarding',
      name: 'Onboarding',
      component: Onboarding,
      beforeEnter: (to, from, next) => {
      if ($cookies.isKey("aW50cmFuZXRjcmVkZW50aWFscw==")) {
        next()
      }
      else {
        next({
          path: "/Login"
        })
      }
    }
    },
    {
      path: '/Offboarding',
      name: 'Offboarding',
      component: Offboarding,
      beforeEnter: (to, from, next) => {
      if ($cookies.isKey("aW50cmFuZXRjcmVkZW50aWFscw==")) {
        next()
      }
      else {
        next({
          path: "/Login"
        })
      }
    }
    },
    {
      path: '/Transfer',
      name: 'Transfer',
      component: Transfer,
      beforeEnter: (to, from, next) => {
      if ($cookies.isKey("aW50cmFuZXRjcmVkZW50aWFscw==")) {
        next()
      }
      else {
        next({
          path: "/Login"
        })
      }
    }
    },
    {
      path: '/Templates',
      name: 'Templates',
      component: Templates,
      beforeEnter: (to, from, next) => {
      if ($cookies.isKey("aW50cmFuZXRjcmVkZW50aWFscw==")) {
        next()
      }
      else {
        next({
          path: "/Login"
        })
      }
    }
    },
    {
      path: '*',
      redirect: '/Login'
    }
  ]
});
